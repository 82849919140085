import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 16px 24px;
    border-bottom: 1px solid ${theme.colors.greyShade50};
  `
);

export const ListItem = styled.div(
  ({ theme }) => css`
    ${theme.fonts.HelveticaLight}
    color: ${theme.colors.textPrimary};
    font-size: 10px;
    line-height: normal;
    width: max-content;
  `
);

export const ListItemWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 4px 6px;
    border-radius: 4px;
    &:hover {
      background: ${theme.colors.greyShade50};
    }
  `
);

export const DropDownWrapper = styled.div(
  ({ theme }) => css`
    position: relative;
    background-color: ${theme.colors.greyShade50};
    border-radius: 4px;
    padding: 4px 6px;
    cursor: pointer;
    z-index: 99;
  `
);

export const Content = styled.p(
  ({ theme }) => css`
    margin: 0;
    color: ${theme.colors.textPrimary};
    font-size: 10px;
    line-height: normal;
    width: max-content;
  `
);

export const DropDownList = styled.div(
  ({ theme }) => css`
    overflow: hidden;
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 0;
    top: 28px;
    width: 231px;
    padding-bottom: 8px;
    border-radius: 8px;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.25);
    background: ${theme.colors.blackShades800};
  `
);
export const ItemWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 12px 16px;
    &:hover {
      background: ${theme.colors.greyShade500};
    }
  `
);

export const Item = styled.p(
  ({ theme }) => css`
    margin: 0;
    font-size: 12px;
    line-height: normal;
    color: ${theme.colors.white};
    ${theme.fonts.HelveticaLight}
  `
);

export const ColorBox = styled.div(
  ({ color }: { color: string }) => css`
    width: 12px;
    height: 12px;
    border-radius: 4px;
    background: ${color};
  `
);
