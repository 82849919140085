import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const CheckBoxLabel = styled.label(
  ({ theme }) => css`
    font-size: 12px;
    ${theme.fonts.AvenirMedium};
    cursor: pointer;
  `
);

export const InputCheckbox = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;
