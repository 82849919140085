import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div<{
  openDropdown: boolean;
  active: boolean;
  isMobile: boolean;
}>(
  ({ theme, openDropdown, active, isMobile }) => css`
    display: flex;
    position: relative;
    padding: 16px;
    border-radius: 8px;
    align-items: center;
    justify-content: ${active ? "space-between" : "center"};
    cursor: pointer;
    background-color: ${openDropdown
      ? theme.colors.primaryShade100
      : "inherit"};
    ${!isMobile &&
    css`
      &:hover {
        background-color: ${openDropdown
          ? theme.colors.primaryShade100
          : theme.colors.grey300};
      }
    `}
    @media (max-width: ${theme.breakpoints.xl}) {
      padding: 12px 16px;
    }
  `
);
export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.div<{
  openDropdown: boolean;
  active: boolean;
  isMobile: boolean;
}>(
  ({ theme, openDropdown, isMobile, active }) => css`
    padding-left: ${active ? "12px" : "0"};
    font-size: 14px;
    color: ${openDropdown ? theme.colors.primary600 : "inherit"};
    transition: all 0.3s ease-in-out;
    white-space: nowrap;
    overflow: hidden;
    width: ${active ? "195px" : "0"};
    ${!isMobile &&
    css`
      @media (max-width: ${theme.breakpoints.xl}) {
        width: ${active ? "167px" : "0"};
        font-size: 13px;
      }
    `}
  `
);

export const ArrowIconWrapper = styled.div<{ active: boolean }>(
  ({ active }) => css`
    height: 24px;
    transition: transform 0.3s ease-in-out;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    white-space: nowrap;
    width: ${active ? "24px" : "0"};
    &.rotate {
      transform: rotate(180deg);
    }
  `
);

export const ExamplesWrapper = styled.div<{ openDropdown: boolean }>(
  ({ openDropdown }) => css`
    margin-left: 26px;
    align-self: flex-start;
    overflow: hidden;
    transition: height 0.3s ease-in-out;
    height: ${openDropdown ? "auto" : "0px"};
    opacity: ${openDropdown ? 1 : 0};
    transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  `
);

export const ExamplesItem = styled.p<{ clicked: boolean; isMobile: boolean }>(
  ({ theme, clicked, isMobile }) => css`
    font-weight: 500;
    font-size: 12px;
    padding: 10px 16px 10px 26px;
    border-left: 1px solid ${theme.colors.grey300};
    margin: 0;
    cursor: pointer;
    color: ${clicked ? theme.colors.primary600 : "#6c6c6c"};
    text-decoration: ${clicked && "underline"};
    ${!isMobile &&
    css`
      &:hover {
        color: ${clicked ? theme.colors.primary600 : theme.colors.black005};
        text-decoration: underline;
      }
    `}
  `
);

export const HoverItem = styled.div(
  ({ theme }) => css`
    position: absolute;
    left: 75px;
    z-index: 10;
    padding: 6px 12px;
    border-radius: 8px;
    width: max-content;
    background: ${theme.colors.black005};
    color: ${theme.colors.white};
    font-size: 12px;

    &::before {
      content: "";
      position: absolute;
      left: -6px;
      top: 45%;
      transform: translateY(-50%);
      border-width: 6px;
      border-style: solid;
      border-color: ${theme.colors.black005};
      rotate: 45deg;
    }
  `
);
