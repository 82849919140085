import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div(
  ({ theme }) => css`
    border-radius: 12px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.09);
    background-color: ${theme.colors.white};
    overflow: hidden;
    & span {
      background-color: ${theme.colors.greyShade50};
    }
  `
);

export const Header = styled.div(
  ({ theme }) => css`
    padding: 20px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${theme.colors.greyShade50};
  `
);

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Title = styled.span`
  width: 124px;
  height: 12px;
  border-radius: 4px;
`;
export const SubTitle = styled.span`
  width: 149px;
  height: 22px;
  border-radius: 4px;
`;
export const RightSide = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
export const RightSideSmallDiv = styled.span`
  border-radius: 8px;
  width: 82px;
  height: 36px;
`;
export const RightSideDiv = styled.span`
  border-radius: 8px;
  width: 200px;
  height: 36px;
`;

//
export const MiddleSection = styled.div(
  ({ theme }) => css`
    padding: 16px 24px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-bottom: 1px solid ${theme.colors.greyShade50};
  `
);

export const MiddleSectionItem = styled.span<{ width: number }>(
  ({ width }) => css`
    width: ${width}px;
    height: 20px;
    border-radius: 4px;
  `
);

export const MainSection = styled.div`
  padding: 24px;
  display: flex;
`;
export const ChartHolder = styled.span`
  border-radius: 12px;
  width: 100%;
  height: 375px;
`;
