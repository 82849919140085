import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DateRangeWrapper,
  DateRangeCustomItem,
  DateRangeItem,
  Content,
  DropDownWrapper,
  ListItem,
  DropDown,
  ListItemTitle,
  ContentTitle,
  IconWrapper,
  CalendarWrapper,
  ListItemContainer,
} from "./DashboardDateFilter.style";
import Icon from "../../shared/icon/Icon";
import { RangeType } from "../../../enums/enum";
import { DateRangePicker } from "rsuite";
import moment from "moment";
import {
  DateFilterDropdownData,
  DateFilterRangeData,
} from "../../../helpers/helper";

interface IProps {
  selectedDateRange: { type: RangeType; range: Array<string> };
  handleSelectDate: ({
    type,
    range,
  }: {
    type: RangeType;
    range: Array<string>;
  }) => void;
}

const DashboardDateFilter = ({
  selectedDateRange,
  handleSelectDate,
}: IProps) => {
  const { t, i18n } = useTranslation();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [openDropDown, setOpenDropDown] = useState(false);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setOpenDropDown(false);
    }
  };

  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const isSelectedMore = useMemo(() => {
    const item = DateFilterDropdownData.find(
      (item) => item.type === selectedDateRange.type
    );
    return Boolean(item);
  }, [selectedDateRange.type]);

  return (
    <div>
      <DateRangeWrapper>
        <DateRangeCustomItem
          isSelected={selectedDateRange.type === RangeType.Custom}
          onClick={() => {}}
        >
          <Icon
            fill={
              selectedDateRange.type === RangeType.Custom
                ? "primary"
                : "default"
            }
            iconName="calendar"
            height={16}
            width={16}
          />
          {selectedDateRange.type === RangeType.Custom ? (
            <>
              {moment(selectedDateRange.range[0]).format("MMM D, YYYY")} -{" "}
              {moment(selectedDateRange.range[1]).format("MMM D, YYYY")}
            </>
          ) : (
            <> {t("DashboardDateFilter.CustomTitle")}</>
          )}
        </DateRangeCustomItem>
        {DateFilterRangeData.map((item, index) => (
          <DateRangeItem
            key={index}
            isSelected={selectedDateRange.type === item.type}
            onClick={() =>
              handleSelectDate({
                type: item.type,
                range: item.range,
              })
            }
          >
            {t(item.title)}
          </DateRangeItem>
        ))}
        <CalendarWrapper custom={selectedDateRange.type === RangeType.Custom}>
          <DateRangePicker
            caretAs={null}
            appearance="subtle"
            placeholder="Subtle"
            showHeader={false}
            ranges={[]}
            format="yyyy/MM/dd"
            style={{ width: "100%", cursor: "pointer", height: "100%" }}
            defaultCalendarValue={
              selectedDateRange.type === RangeType.Custom
                ? [
                    moment(selectedDateRange.range[0]).toDate(),
                    moment(selectedDateRange.range[1]).toDate(),
                  ]
                : [moment().subtract(1, "month").toDate(), moment().toDate()]
            }
            onChange={(data) => {
              if (data) {
                const [fromDate, toDate] = data;
                handleSelectDate({
                  type: RangeType.Custom,
                  range: [
                    moment(fromDate).format("YYYY-MM-DD"),
                    moment(toDate).format("YYYY-MM-DD"),
                  ],
                });
                try {
                } catch (error) {}
              }
            }}
          />
        </CalendarWrapper>

        <DropDown
          isSelected={isSelectedMore}
          ref={dropdownRef}
          onClick={() => setOpenDropDown((prev) => !prev)}
        >
          <Content>
            <ContentTitle isSelected={isSelectedMore}>
              {t("DashboardDateFilter.MoreTitle")}
            </ContentTitle>
            <IconWrapper className={openDropDown ? "rotate" : ""}>
              <Icon
                fill={isSelectedMore ? "primary" : "default"}
                iconName="arrowDown"
                width={16}
                height={16}
              />
            </IconWrapper>
          </Content>
          {openDropDown && (
            <DropDownWrapper>
              {DateFilterDropdownData.map((item, index) => (
                <ListItem
                  key={index}
                  onClick={() => {
                    handleSelectDate({
                      type: item.type,
                      range: item.range,
                    });
                  }}
                >
                  <ListItemContainer>
                    <ListItemTitle>{t(item.title)}</ListItemTitle>
                    {selectedDateRange.type === item.type && (
                      <Icon
                        iconName="checkMark"
                        width={20}
                        height={20}
                        fill="primary"
                      />
                    )}
                  </ListItemContainer>
                </ListItem>
              ))}
            </DropDownWrapper>
          )}
        </DropDown>
      </DateRangeWrapper>
    </div>
  );
};

export default DashboardDateFilter;
