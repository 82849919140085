import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { CardTypes, ChartMode, PeriodType } from "../../../../enums/enum";
import {
  formatNumber,
  generateGridData,
  GridDataItem,
  sumRevenueExpensesWithName,
} from "../../../../helpers/dashboardHelper";
import { FinancialRecord } from "../../../../store/dashboard/dashboard.types";
import DashboardChartCard from "../../../shared/card/Card";
import GroupItemBarChart from "../groupItemChart/GroupItemBarChart";
import { ChartContainer, GridContainer } from "./GridChart.style";

interface GridChartProps {
  data: Array<FinancialRecord>;
  periodType: PeriodType;
  handleViewDetail?: (id: number | string) => void;
}
const GridChart = ({ data, periodType, handleViewDetail }: GridChartProps) => {
  const { t } = useTranslation();
  const gridData = useMemo(
    () => generateGridData(data, periodType),
    [data, periodType]
  );

  const hasData = () => {
    return gridData.some(
      (item) =>
        item.data &&
        item.data.length > 0 &&
        !item.data.every((el) => el[item.title] === 0)
    );
  };

  const shouldShow = (item: GridDataItem): boolean => {
    return Boolean(
      item.data &&
        item.data.length > 0 &&
        !item.data.every((el) => el[item.title] === 0)
    );
  };

  return (
    <GridContainer>
      {hasData() ? (
        <>
          {gridData.map((gridItem, index) => {
            if (shouldShow(gridItem))
              return (
                <DashboardChartCard
                  key={index}
                  selectedPeriodType={periodType}
                  selectedViewType={ChartMode.Grid}
                  type={CardTypes.SMALL}
                  title={gridItem.title}
                  value={formatNumber(
                    sumRevenueExpensesWithName(data, gridItem.title)
                  )}
                  handleViewDetail={
                    handleViewDetail && gridItem.id
                      ? () => handleViewDetail(gridItem.id)
                      : undefined
                  }
                >
                  <ChartContainer>
                    <GroupItemBarChart
                      title={gridItem.title}
                      colorIndex={index}
                      data={gridItem.data}
                    />
                  </ChartContainer>
                </DashboardChartCard>
              );
            return null;
          })}
        </>
      ) : (
        <div>{t("GridChart.NoData")}</div>
      )}
    </GridContainer>
  );
};

export default GridChart;
