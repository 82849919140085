import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { PeriodType } from "../../enums/enum";
import { getDateInfo } from "../../helpers/helper";
import axiosInstance from "../../serveces/interceptor";
import config from "../../utils/config";
import {
  CashFlow,
  FinancialGroupByIdRequest,
  FinancialGroupsRequest,
  FinancialInDetailRequest,
  ProfitLossResponseRecord,
} from "./dashboard.types";

export const GET_REVENUE_GROUPS_INFO = "GET_REVENUE_GROUPS_INFO";
export const GET_REVENUE_GROUP_BY_ID_INFO = "GET_REVENUE_GROUP_BY_ID_INFO";
export const GET_REVENUE_IN_DETAIL = "GET_REVENUE_IN_DETAIL";

export const GET_REVENUE_GROUPS_EXCEL = "GET_REVENUE_GROUPS_EXCEL";
export const GET_REVENUE_GROUP_BY_ID_EXCEL = "GET_REVENUE_GROUP_BY_ID_EXCEL";

export const GET_EXPENSES_GROUPS_INFO = "GET_EXPENSES_GROUPS_INFO";
export const GET_EXPENSES_GROUP_BY_ID_INFO = "GET_EXPENSES_GROUP_BY_ID_INFO";
export const GET_EXPENSES_IN_DETAIL = "GET_EXPENSES_IN_DETAIL";

export const GET_EXPENSES_INFO = "GET_EXPENSES_INFO";
export const GET_PROFIT_LOSS_INFO = "GET_PROFIT_LOSS_INFO";
export const GET_REVENUE_INFO = "GET_REVENUE_INFO";
export const GET_REPORTAL_REPORT = "GET_REPORTAL_REPORT";
export const GET_CASHFLOW_INFO = "GET_CASHFLOW_INFO";

export const GET_MARKET_COMPANY_INFO_BY_ID = "GET_MARKET_COMPANY_INFO_BY_ID";
export const GET_MARKET_All_COMPANY_INFO = "GET_MARKET_All_COMPANY_INFO";

export const getRevenueGroups = createAsyncThunk(
  GET_REVENUE_GROUPS_INFO,
  async (data: FinancialGroupsRequest) => {
    const response: AxiosResponse = await axiosInstance.get(
      config.api.endpoints.dashboard.revenueChartByGroups,
      {
        params: data,
      }
    );
    return response.data;
  }
);

export const getRevenueGroupById = createAsyncThunk(
  GET_REVENUE_GROUP_BY_ID_INFO,
  async (data: FinancialGroupByIdRequest) => {
    const response: AxiosResponse = await axiosInstance.get(
      config.api.endpoints.dashboard.revenueChartsByGroupId,
      {
        params: data,
      }
    );
    return response.data;
  }
);

export const getRevenueInDetail = createAsyncThunk(
  GET_REVENUE_IN_DETAIL,
  async (data: FinancialInDetailRequest) => {
    const response: AxiosResponse = await axiosInstance.get(
      config.api.endpoints.dashboard.revenueChartsInDetail,
      {
        params: { ...data, pageSize: 50 },
      }
    );
    return response.data;
  }
);

export const getRevenueGroupsExcel = createAsyncThunk(
  GET_REVENUE_GROUPS_EXCEL,
  async (data: FinancialGroupsRequest) => {
    const response: AxiosResponse = await axiosInstance.get(
      config.api.endpoints.dashboard.revenueChartByGroupsExcel,
      {
        params: data,
      }
    );
    return response.data;
  }
);

export const getExpensesGroupsExcel = createAsyncThunk(
  GET_REVENUE_GROUPS_EXCEL,
  async (data: FinancialGroupsRequest) => {
    const response: AxiosResponse = await axiosInstance.get(
      config.api.endpoints.dashboard.expensesChartByGroupsExcel,
      {
        params: data,
      }
    );
    return response.data;
  }
);

export const getExpensesGroups = createAsyncThunk(
  GET_EXPENSES_GROUPS_INFO,
  async (data: FinancialGroupsRequest) => {
    const response: AxiosResponse = await axiosInstance.get(
      config.api.endpoints.dashboard.expensesChartByGroups,
      {
        params: data,
      }
    );
    return response.data;
  }
);

export const getExpensesGroupById = createAsyncThunk(
  GET_EXPENSES_GROUP_BY_ID_INFO,
  async (data: FinancialGroupByIdRequest) => {
    const response: AxiosResponse = await axiosInstance.get(
      config.api.endpoints.dashboard.expensesChartsByGroupId,
      {
        params: data,
      }
    );
    return response.data;
  }
);

export const getExpensesInDetail = createAsyncThunk(
  GET_EXPENSES_IN_DETAIL,
  async (data: FinancialInDetailRequest) => {
    const response: AxiosResponse = await axiosInstance.get(
      config.api.endpoints.dashboard.expensesChartsInDetail,
      {
        params: { ...data, pageSize: 50 },
      }
    );
    return response.data;
  }
);

// old
export const getExpensesInfo = createAsyncThunk(
  GET_EXPENSES_INFO,
  async ({
    periodType,
    fromDate,
    toDate,
  }: {
    periodType: PeriodType;
    fromDate: string;
    toDate: string;
  }) => {
    const response: AxiosResponse = await axiosInstance.get(
      config.api.endpoints.chart.expenses,
      {
        params: {
          periodType: periodType,
          fromDate: getDateInfo(fromDate),
          toDate: getDateInfo(toDate),
        },
      }
    );

    return response.data;
  }
);

export const getProfitLossInfo = createAsyncThunk(
  GET_PROFIT_LOSS_INFO,
  async ({
    periodType,
    fromDate,
    toDate,
  }: {
    periodType: PeriodType;
    fromDate: string;
    toDate: string;
  }) => {
    const response: AxiosResponse = await axiosInstance.get(
      config.api.endpoints.chart.profitLoss,
      {
        params: {
          PeriodType: periodType,
          FromDate: getDateInfo(fromDate),
          ToDate: getDateInfo(toDate),
        },
      }
    );

    return {
      data: response.data.data,
      lastUpdateTime: response.data.lastUpdateTime,
      periodType,
    } as {
      data: Array<ProfitLossResponseRecord>;
      lastUpdateTime: string;
      periodType: PeriodType;
    };
  }
);
export const getCashFlowInfo = createAsyncThunk(
  GET_CASHFLOW_INFO,
  async ({
    periodType,
    fromDate,
    toDate,
  }: {
    periodType: PeriodType;
    fromDate: string;
    toDate: string;
  }) => {
    const response: AxiosResponse = await axiosInstance.get(
      config.api.endpoints.chart.cashFlow,
      {
        params: {
          PeriodType: periodType,
          FromDate: getDateInfo(fromDate),
          ToDate: getDateInfo(toDate),
        },
      }
    );

    return {
      data: response.data.data,
      lastUpdateTime: response.data.lastUpdateTime,
      periodType,
    } as {
      data: Array<CashFlow>;
      lastUpdateTime: string;
      periodType: PeriodType;
    };
  }
);

export const getReportalReport = createAsyncThunk(
  GET_REPORTAL_REPORT,
  async ({ year }: { year: number }) => {
    const response: AxiosResponse = await axiosInstance.get(
      config.api.endpoints.reporting.getReportalReport,
      {
        params: {
          year,
        },
      }
    );
    return response.data;
  }
);

export const getMarketCompanyInfo = createAsyncThunk(
  GET_MARKET_COMPANY_INFO_BY_ID,
  async ({
    identificationCode,
    year,
    rePortalMappingType,
  }: {
    identificationCode: number;
    year: number;
    rePortalMappingType: 1 | 2;
  }) => {
    const response: AxiosResponse = await axiosInstance.get(
      config.api.endpoints.reporting.getMarketCompanyInfo,
      { params: { identificationCode, year, rePortalMappingType } }
    );

    return response.data;
  }
);

export const getMarketAllCompanyInfo = createAsyncThunk(
  GET_MARKET_All_COMPANY_INFO,
  async (startsWith: string) => {
    const response: AxiosResponse = await axiosInstance.get(
      config.api.endpoints.reporting.getMarketAllCompanyInfo,
      { params: { startsWith } }
    );
    return response.data;
  }
);
