import React from "react";
import { CheckBoxLabel, InputCheckbox } from "./CheckBox.style";
import { ReactComponent as Checked } from "../../../assets/svgs/checked.svg";
import { ReactComponent as UnChecked } from "../../../assets/svgs/unchecked.svg";
import { ReactComponent as PartialChecked } from "../../../assets/svgs/partialChecked.svg";

interface checkBoxProps {
  checkType: "checked" | "partialChecked" | "unChecked";
  title: string;
  handleChange: () => void;
}

const CheckBox = ({ checkType, title, handleChange }: checkBoxProps) => {
  return (
    <InputCheckbox
      onClick={() => {
        handleChange();
      }}
      role="checkbox"
      aria-checked={checkType === "checked"}
    >
      {checkType === "checked" && <Checked />}
      {checkType === "partialChecked" && <PartialChecked />}
      {checkType === "unChecked" && <UnChecked />}

      <CheckBoxLabel htmlFor="scales">{title}</CheckBoxLabel>
    </InputCheckbox>
  );
};

export default CheckBox;
