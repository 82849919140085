import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../store";

import {
  CustomTable,
  ExportExcelWrapper,
  TableRow,
  TableRowItem,
  Wrapper,
} from "./WorkingOutcome.style";
import TableHeader from "../../../../../components/shared/tableHeader/TableHeader";
import { formatNumber } from "../../../../../helpers/dashboardHelper";
import ReportFilter from "../../../../../components/desktop/reportFilter/ReportFilter";
import Loader from "../../../../../components/shared/loader/Loader";
import {
  downloadFile,
  generatePreviousYearsData,
} from "../../../../../helpers/helper";
import Button from "../../../../../components/shared/button/Button";
import { useTranslation } from "react-i18next";
import {
  getOutcomeDetailFileUrl,
  getWorkingOutComeDetailedReport,
} from "../../../../../store/report/reportAction";

const headerArray = [
  "WorkingOutcome.AccountId",
  "WorkingOutcome.AccountName",
  "WorkingOutcome.RePortalMappingName",
  "WorkingOutcome.Amount",
];

const WorkingOutCome = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [year, setYear] = useState(new Date().getFullYear() - 1);

  const workingOutDetailed = useAppSelector(
    (state) => state.report.reportal.workingOutComeDetailed
  );
  const [fileDownloadLoading, setFileDownloadLoading] = useState(false);

  const loading = useAppSelector((state) => state.dashboard.loading);
  const selectedCompany = useAppSelector((state) => state.user.selectedCompany);

  useEffect(() => {
    dispatch(getWorkingOutComeDetailedReport({ year }));
  }, [dispatch, year, selectedCompany]);

  const handleFileDownload = () => {
    setFileDownloadLoading(true);
    dispatch(getOutcomeDetailFileUrl({ year }))
      .then(({ payload }) => {
        downloadFile(payload as string);
        setTimeout(() => {
          setFileDownloadLoading(false);
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          setFileDownloadLoading(false);
        }, 500);
      });
  };

  const submit = ({ year: currentYear }: { year?: number | undefined }) => {
    if (currentYear) {
      setYear(currentYear);
    }
  };

  return (
    <Wrapper>
      <ReportFilter
        activeYear={year}
        handleSubmit={submit}
        years={generatePreviousYearsData(1)}
      />
      <ExportExcelWrapper>
        <Button
          isLoading={fileDownloadLoading}
          onClick={handleFileDownload}
          title={t("Export.FileExport")}
        ></Button>
      </ExportExcelWrapper>
      {loading === "pending" ? (
        <Loader />
      ) : (
        <CustomTable>
          <TableHeader headerNames={headerArray} />
          <tbody>
            {workingOutDetailed.map((item, index) => (
              <TableRow key={index}>
                <TableRowItem>{item.accountId}</TableRowItem>
                <TableRowItem>{item.accountName}</TableRowItem>
                <TableRowItem>{item.rePortalMappingName}</TableRowItem>
                <TableRowItem>{formatNumber(item.amount)}</TableRowItem>
              </TableRow>
            ))}
          </tbody>
        </CustomTable>
      )}
    </Wrapper>
  );
};

export default WorkingOutCome;
