import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Icon, { IconNameOptions } from "../../../shared/icon/Icon";
import {
  ArrowIconWrapper,
  ExamplesItem,
  ExamplesWrapper,
  HoverItem,
  Title,
  TitleWrapper,
  Wrapper,
} from "./SideBarNavigationWithDropdown.style";

import { useLocation, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

interface DropDownItem {
  labelKey: string;
  path: string;
  hasPermission: boolean;
}

interface SideBarDropDownProps {
  icon: IconNameOptions;
  title: string;
  active: boolean;
  dropdownItems: DropDownItem[];
  setOpen: (state: boolean) => void;
  handleClose?: () => void;
}

const SideBarNavigationWithDropdown = ({
  icon,
  title,
  active,
  setOpen,
  handleClose,
  dropdownItems,
}: SideBarDropDownProps) => {
  const [openDropDown, setOpenDropDown] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (!dropdownItems.some((item) => location.pathname.includes(item.path))) {
      setOpenDropDown(false);
    }
  }, [location, dropdownItems]);

  useEffect(() => {
    if (!active) {
      setOpenDropDown(false);
    }
  }, [active]);

  const handleOpenSideBar = () => {
    if (!active) {
      setOpen(true);
    }
  };

  return (
    <>
      <Wrapper
        isMobile={isMobile}
        active={active}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        openDropdown={dropdownItems.some((item) =>
          location.pathname.includes(item.path)
        )}
        onClick={() => {
          handleOpenSideBar();
          if (active) {
            setOpenDropDown((prev) => !prev);
          } else {
            setTimeout(() => {
              setOpenDropDown((prev) => !prev);
            }, 200);
          }
        }}
      >
        <TitleWrapper>
          <Icon
            fill={
              dropdownItems.some((item) =>
                location.pathname.includes(item.path)
              )
                ? "primary"
                : "default"
            }
            iconName={icon}
          />
          <Title
            isMobile={isMobile}
            active={active}
            openDropdown={dropdownItems.some((item) =>
              location.pathname.includes(item.path)
            )}
          >
            {title}
          </Title>
        </TitleWrapper>
        <ArrowIconWrapper
          active={active}
          className={openDropDown ? "rotate" : ""}
        >
          <Icon
            fill={
              dropdownItems.some((item) =>
                location.pathname.includes(item.path)
              )
                ? "primary"
                : "default"
            }
            iconName="arrowDown"
          />
        </ArrowIconWrapper>
        {isHovered && !active && <HoverItem>{title}</HoverItem>}
      </Wrapper>
      {openDropDown && (
        <ExamplesWrapper openDropdown={openDropDown}>
          {dropdownItems
            .filter((i) => i.hasPermission)
            .map((item) => (
              <ExamplesItem
                key={item.path}
                isMobile={isMobile}
                clicked={location.pathname.includes(item.path)}
                onClick={() => {
                  navigate(item.path);
                  if (handleClose) handleClose();
                }}
              >
                {t(item.labelKey)}
              </ExamplesItem>
            ))}
        </ExamplesWrapper>
      )}
    </>
  );
};

export default SideBarNavigationWithDropdown;
