import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { CardTypes } from "../../../enums/enum";

interface StyleProps {
  type: CardTypes;
}

export const Wrapper = styled.div(
  ({ theme }) => css`
    width: 100%;
    border-radius: 12px;
    background: ${theme.colors.white};
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.09);
    ${theme.fonts.HelveticaNeue}
  `
);

// todo  need refactor
export const Header = styled.div<StyleProps>(
  ({ type, theme }) => css`
    padding: ${type === CardTypes.LARGE ? "20px 24px" : "16px 24px"};
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${theme.colors.greyShade50};
  `
);

export const TitleWrapper = styled.div<StyleProps>(
  ({ type }) => css`
    display: flex;
    flex-direction: column;
    gap: ${type === CardTypes.LARGE ? "4px" : "2px"};
  `
);

export const Title = styled.h6(
  ({ theme }) => css`
    ${theme.fonts.HelveticaLight}
    color: ${theme.colors.textSecondary};
    font-size: 14px;
    line-height: 17px;
  `
);
export const Value = styled.p<StyleProps>(
  ({ theme, type }) => css`
    color: ${theme.colors.textPrimary};
    margin: 0;
    font-size: ${type === CardTypes.LARGE ? "24px" : "18px"};
    font-weight: 700;
    line-height: normal;
  `
);

export const ViewDetailWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const ViewDetailedTitle = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.primary600};
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.084px;
  `
);
export const ViewDetailedIcon = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ChartDetailedWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
