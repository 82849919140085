import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import {
  ChartDetailedWrapper,
  Header,
  Title,
  TitleWrapper,
  Value,
  ViewDetailedIcon,
  ViewDetailedTitle,
  ViewDetailWrapper,
  Wrapper,
} from "./Card.style";
import Icon from "../icon/Icon";

import {
  generateCardChartModes,
  generateGridChartTypes,
  generatePeriodTypes,
} from "../../../helpers/helper";

import FilterSelector from "../dashboard/filterSelector/FilterSelector";

import ChartTypeSwitcher from "../dashboard/chartTypeSwitcher/ChartTypeSwitcher";
import {
  CardTypes,
  ChartMode,
  ChartType,
  PeriodType,
} from "../../../enums/enum";

interface DashboardChartCardProps {
  selectedPeriodType?: PeriodType;
  selectedChartType?: ChartType;
  selectedViewType: ChartMode;
  hasBranch?: boolean;
  hasLine?: boolean;
  type: CardTypes;
  title: string;
  value: string | number;
  children: ReactNode;
  handleChartType?: (type: ChartType) => void;
  handlePeriodType?: (periodType: PeriodType) => void;
  handleViewMode?: (viewType: ChartMode) => void;
  handleViewDetail?: () => void;
}
const DashboardChartCard = ({
  selectedPeriodType,
  selectedChartType,
  selectedViewType,
  type,
  hasBranch,
  hasLine,
  title,
  value,
  children,
  handleChartType,
  handlePeriodType,
  handleViewMode,
  handleViewDetail,
}: DashboardChartCardProps) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Header type={type}>
        <TitleWrapper type={type}>
          <Title>{title}</Title>
          <Value type={type}>{value}</Value>
        </TitleWrapper>
        {type === CardTypes.LARGE && (
          <ChartDetailedWrapper>
            {selectedPeriodType && handlePeriodType && (
              <FilterSelector
                selectedValue={selectedPeriodType.toString()}
                handlePeriod={(value) => handlePeriodType(+value as PeriodType)}
                options={generatePeriodTypes()}
              />
            )}
            {(hasLine || hasBranch) && selectedChartType && handleChartType && (
              <FilterSelector
                selectedValue={selectedChartType.toString()}
                handlePeriod={(value) => {
                  handleChartType(+value as ChartType);
                }}
                options={generateGridChartTypes(
                  Boolean(hasLine),
                  Boolean(hasBranch)
                )}
              />
            )}
            {handleViewMode && (
              <ChartTypeSwitcher
                viewOptions={generateCardChartModes()}
                selectedViewMode={selectedViewType}
                handleViewMode={handleViewMode}
              />
            )}
          </ChartDetailedWrapper>
        )}
        {type === CardTypes.SMALL && handleViewDetail && (
          <ViewDetailWrapper>
            <ViewDetailedTitle onClick={handleViewDetail}>
              {t("Card.ViewDetailsTitle")}
            </ViewDetailedTitle>

            <ViewDetailedIcon>
              <Icon iconName="rightArrow" height={20} width={20} />
            </ViewDetailedIcon>
          </ViewDetailWrapper>
        )}
      </Header>
      {children}
    </Wrapper>
  );
};

export default DashboardChartCard;
