import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Title = styled.h3(
  ({ theme }) => css`
    color: ${theme.colors.primary600};
    font-size: 20px;
  `
);

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Wrapper = styled.div<{ opened: boolean; isMobile: boolean }>(
  ({ theme, opened, isMobile }) => css`
    position: relative;
    cursor: pointer;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 100px;
    background-color: ${opened ? theme.colors.grey500 : theme.colors.grey300};
    ${!isMobile &&
    css`
      &:hover {
        background-color: ${opened
          ? theme.colors.grey500
          : theme.colors.grey400};
      }
    `}
  `
);
export const Content = styled.p(
  ({ theme }) => css`
    margin: 0;
    color: ${theme.colors.black005};
    font-size: 14px;
  `
);

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  padding-left: 6px;
`;

export const ArrowWrapper = styled.div`
  height: 24px;
  transition: transform 0.3s ease-in-out;

  &.rotate {
    transform: rotate(180deg);
  }
`;

export const DropDownContent = styled.div(
  ({ theme }) => css`
    z-index: 999999;
    width: 269px;
    position: absolute;
    top: 45px;
    right: 0;
    background-color: ${theme.colors.white};
    padding: 8px 0;
    border-radius: 12px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
  `
);

export const DropDownItem = styled.div<{ logOut?: boolean; isMobile: boolean }>(
  ({ theme, logOut, isMobile }) => css`
    padding: 10px 24px;
    display: flex;
    gap: 8px;
    align-items: center;
    ${!isMobile &&
    css`
      &:hover {
        background-color: ${theme.colors.grey100};
      }
    `}
    ${logOut && `border-top: 1px solid ${theme.colors.bg002};`}
  `
);

// TODO color #EC1818 it is Red color
export const DropDownTitle = styled.div<{ logOut?: boolean }>(
  ({ logOut, theme }) => css`
    color: ${logOut ? "#EC1818" : theme.colors.black005};
    font-size: 14px;
  `
);
