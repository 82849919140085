import React from "react";
import SmallCardSkeleton from "../../smallCardSkeleton/SmallCardSkeleton";

import {
  Header,
  LeftSide,
  RightSide,
  BarChartItemWrapper,
  Wrapper,
} from "./CardGroupsSkeleton.style";

const CardGroupsSkeleton = () => {
  return (
    <Wrapper>
      <Header>
        <LeftSide />
        <RightSide />
      </Header>
      <BarChartItemWrapper>
        <SmallCardSkeleton />
        <SmallCardSkeleton />
        <SmallCardSkeleton />
        <SmallCardSkeleton />
      </BarChartItemWrapper>
    </Wrapper>
  );
};

export default CardGroupsSkeleton;
