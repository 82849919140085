import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const BarChartItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Header = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    & span {
      background: ${theme.colors.greyShade50};
    }
  `
);

export const LeftSide = styled.span`
  width: 180px;
  height: 22px;
  border-radius: 4px;
`;

export const RightSide = styled.span`
  width: 180px;
  height: 36px;
  border-radius: 8px;
`;
