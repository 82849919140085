import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  BarChartTitleContainer,
  GroupBarChartContainer,
  GroupDetailChartWrapper,
  Header,
  NavLink,
  SkeletonWrapper,
  Title,
  TitleWrapper,
  TreeChartContainer,
  Wrapper,
} from "./RevenueByGroupId.style";

import BigCardSkeleton from "../../../../components/shared/dashboard/skeletons/bigCardSkeleton/BigCardSkeleton";
import { generateGridChartModes } from "../../../../helpers/helper";
import Filters from "../../../../components/shared/dashboard/filters/Filters";
import CardGroupsSkeleton from "../../../../components/shared/dashboard/skeletons/cardGroupsSkeleton/CardGroupsSkeleton";
import DashboardChartCard from "../../../../components/shared/card/Card";

import { useAppDispatch, useAppSelector } from "../../../../store";
import { getRevenueGroupById } from "../../../../store/dashboard/dashboardAction";
import {
  getCompanyBranch,
  getCompanyBusinessLine,
} from "../../../../store/company/companyAction";
import {
  CardTypes,
  ChartMode,
  ChartType,
  PeriodType,
  RangeType,
} from "../../../../enums/enum";

import { FinancialGroupByIdRequest } from "../../../../store/dashboard/dashboard.types";
import GroupBarChart from "../../../../components/desktop/charts/groupBar/GroupBarChart";
import TreeChart from "../../../../components/desktop/charts/treeChart/TreeChart";
import BarChartLabels from "../../../../components/shared/dashboard/barChartLabels/BarChartLabels";

import TableChart from "../../../../components/desktop/charts/tableChart/TableChart";
import ChartTypeSwitcher from "../../../../components/shared/dashboard/chartTypeSwitcher/ChartTypeSwitcher";
import GridChart from "../../../../components/desktop/charts/gridChart/GridChart";
import {
  formatNumber,
  sumRevenueExpenses,
} from "../../../../helpers/dashboardHelper";
import { Link, useNavigate, useParams } from "react-router-dom";
import Icon from "../../../../components/shared/icon/Icon";
import { PageURL } from "../../../../constants/router";
import EmptyDashboard from "../../../../components/shared/emptyDashboard/EmptyDashboard";
import {
  initialRevenueExpensesFilterData,
  updateRevenueFilter,
} from "../../../../store/dashboard/dashboardSlice";

const RevenueByGroupId = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let { groupId } = useParams();

  const [isShowClearAll, setIsShowClearAll] = useState(false);

  const [cardViewMode, setCardViewMode] = useState<ChartMode>(
    ChartMode.Stacked
  );

  const [selectedChartType, setSelectedChartType] = useState<ChartType>(
    ChartType.GROUP
  );
  const [viewMode, setViewMode] = useState<ChartMode>(ChartMode.Grid);

  const revenueFilter = useAppSelector(
    (state) => state.dashboard.revenueFilter
  );

  const companyBusinessLine = useAppSelector(
    (state) => state.company.companyBusinessLine
  ).map((item) => ({ id: item.id.toString(), name: item.name }));

  const companyBranches = useAppSelector(
    (state) => state.company.companyBranch
  ).map((item) => ({ id: item.id.toString(), name: item.name }));

  const revenue = useAppSelector(
    (state) => state.dashboard.revenueGroupByIdChartData
  );

  const revenueGroupsOption = useAppSelector(
    (state) => state.dashboard.revenueGroupByIdOption
  );

  const loading = useAppSelector(
    (state) => state.dashboard.revenueChartLoading
  );

  const hasNoData = useMemo(() => {
    return revenue.data.every((item) => item.sumAmount === 0);
  }, [revenue.data]);

  const totalSum = useMemo(() => {
    return formatNumber(sumRevenueExpenses(revenue.data));
  }, [revenue.data]);

  useEffect(() => {
    const promises = [
      dispatch(getCompanyBusinessLine()),
      dispatch(getCompanyBranch()),
    ];
    Promise.all(promises).finally(() => {});
  }, [dispatch]);

  useEffect(() => {
    if (!groupId) return;
    dispatch(
      getRevenueGroupById({
        periodType: PeriodType.Month,
        fromDate: revenueFilter.dateRange.range[0],
        toDate: revenueFilter.dateRange.range[1],
        chartType: ChartType.GROUP,
        accountMappingGroupId: +groupId,
      })
    );
  }, [dispatch, revenueFilter.dateRange, groupId]);

  const handleClearAll = () => {
    if (!groupId) return;
    dispatch(updateRevenueFilter(initialRevenueExpensesFilterData));
  };

  const handleFilter = ({
    businessLines,
    branches,
    groups,
    periodType,
    chartType,
    dateRange,
    dateType,
  }: {
    businessLines?: Array<string | number>;
    branches?: Array<string | number>;
    groups?: Array<string | number>;
    periodType?: PeriodType;
    chartType?: ChartType;
    dateRange?: Array<string>;
    dateType?: RangeType;
  }) => {
    if (!groupId) return;
    let data: FinancialGroupByIdRequest = {
      periodType: periodType ? periodType : revenueFilter.period,
      fromDate: dateRange?.length
        ? dateRange[0]
        : revenueFilter.dateRange.range[0],
      toDate: dateRange?.length
        ? dateRange[1]
        : revenueFilter.dateRange.range[1],
      chartType: chartType ? chartType : selectedChartType,
      accountMappingGroupId: +groupId,
    };
    if (businessLines?.length || revenueFilter.businessLines.length) {
      data = {
        ...data,
        companyBusinessLineIds: businessLines?.length
          ? businessLines
          : revenueFilter.businessLines,
      };
    }
    if (branches?.length || revenueFilter.branches.length) {
      data = {
        ...data,
        companyBranchIds: branches?.length ? branches : revenueFilter.branches,
      };
    }
    if (groups?.length || revenueFilter.groups.length) {
      data = {
        ...data,
        accountIds: groups?.length ? groups : revenueFilter.groups,
      };
    }

    dispatch(
      updateRevenueFilter({
        dateRange:
          {
            type:
              dateType !== undefined ? dateType : revenueFilter.dateRange.type,
            range: [data.fromDate, data.toDate],
          } || revenueFilter.dateRange,
        period: data.periodType || revenueFilter.period,
        groups: data.accountIds || revenueFilter.groups,
        branches: data.companyBranchIds || revenueFilter.branches,
        businessLines:
          data.companyBusinessLineIds || revenueFilter.businessLines,
      })
    );
  };

  const handleNavigationGroupDetail = (accountId: number | string) => {
    navigate(accountId.toString());
  };

  return (
    <Wrapper>
      <Header>
        <TitleWrapper>
          <Title>{t("RevenueByGroupId.Title")}</Title>
          <NavLink>
            <Link to={PageURL.Revenue.path}>
              <Icon iconName="leftArrow" height={12} width={7} />
              <span> {t("RevenueByGroupId.SubTitle")}</span>
            </Link>
          </NavLink>
        </TitleWrapper>
        <Filters
          isShowClearAll={isShowClearAll}
          groupOptions={revenueGroupsOption}
          selectedDateRange={revenueFilter.dateRange}
          selectedBranches={revenueFilter.branches}
          selectedGroups={revenueFilter.groups}
          selectedBusinessLine={revenueFilter.businessLines}
          branchOptions={companyBranches}
          lineOptions={companyBusinessLine}
          handleDateRange={(dateData) => {
            setIsShowClearAll(true);
            handleFilter({
              dateRange: dateData.range,
              dateType: dateData.type,
            });
          }}
          handleBusinessLines={(businessLines) => {
            setIsShowClearAll(true);
            handleFilter({ businessLines });
          }}
          handleBranches={(branches) => {
            setIsShowClearAll(true);
            handleFilter({ branches });
          }}
          handleGroups={(groups) => {
            setIsShowClearAll(true);
            handleFilter({ groups });
          }}
          handleClearAll={() => {
            setIsShowClearAll(false);
            handleClearAll();
          }}
        />
      </Header>
      {loading.groupById === "pending" ? (
        <SkeletonWrapper>
          <BigCardSkeleton />
          <CardGroupsSkeleton />
        </SkeletonWrapper>
      ) : (
        <>
          {hasNoData ? (
            <EmptyDashboard />
          ) : (
            <>
              <DashboardChartCard
                selectedPeriodType={revenueFilter.period}
                selectedChartType={selectedChartType}
                selectedViewType={cardViewMode}
                type={CardTypes.LARGE}
                title={t("RevenueByGroupId.DashboardCardTitle")}
                value={totalSum}
                hasBranch={Boolean(companyBranches.length)}
                hasLine={Boolean(companyBusinessLine.length)}
                handlePeriodType={(periodType) => {
                  handleFilter({ periodType });
                }}
                handleChartType={(chartType) => {
                  setSelectedChartType(chartType);
                  handleFilter({ chartType });
                }}
                handleViewMode={setCardViewMode}
              >
                {cardViewMode === ChartMode.Stacked && (
                  <GroupBarChartContainer>
                    <BarChartLabels data={revenue.data} />
                    <GroupBarChart
                      data={revenue.data}
                      periodType={revenueFilter.period}
                      stackId={"a"}
                    />
                  </GroupBarChartContainer>
                )}
                {cardViewMode === ChartMode.Tree && (
                  <TreeChartContainer>
                    <TreeChart data={revenue.data} />
                  </TreeChartContainer>
                )}
              </DashboardChartCard>
              <GroupDetailChartWrapper>
                <BarChartTitleContainer>
                  <Title>{t("RevenueByGroupId.BarChartTitle")}</Title>
                  <ChartTypeSwitcher
                    viewOptions={generateGridChartModes()}
                    selectedViewMode={viewMode}
                    handleViewMode={setViewMode}
                  />
                </BarChartTitleContainer>
                {viewMode === ChartMode.Grid && (
                  <GridChart
                    handleViewDetail={
                      selectedChartType === ChartType.GROUP
                        ? handleNavigationGroupDetail
                        : undefined
                    }
                    periodType={revenueFilter.period}
                    data={revenue.data}
                  />
                )}
                {viewMode === ChartMode.Table && (
                  <TableChart
                    handleClick={
                      selectedChartType === ChartType.GROUP
                        ? handleNavigationGroupDetail
                        : undefined
                    }
                    periodType={revenueFilter.period}
                    data={revenue.data}
                  />
                )}
              </GroupDetailChartWrapper>
            </>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default RevenueByGroupId;
