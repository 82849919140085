import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { ChartGroupTypes, PeriodType, RangeType } from "../../enums/enum";
import { LoaderType } from "../type";

import {
  generateCashFlowGroupedChartDataForGroups,
  generateCashFlowOutcome,
  processAccountData,
} from "../../helpers/dashboardHelper";
import {
  CashFlow,
  CashFlowDirectionType,
  FinancialGroupsResponse,
  Filter,
  FrontSideFilter,
  MarketData,
  ReportalReportItem,
  FinancialRecord,
  FinancialInDetailResponse,
  RevenueFilter,
  ExpensesFilter,
  ProfitLossResponse,
} from "./dashboard.types";

import {
  getCashFlowInfo,
  getExpensesGroupById,
  getExpensesGroups,
  getExpensesInDetail,
  getMarketAllCompanyInfo,
  getMarketCompanyInfo,
  getProfitLossInfo,
  getReportalReport,
  getRevenueGroupById,
  getRevenueGroups,
  getRevenueInDetail,
} from "./dashboardAction";
import moment from "moment";
import { getRange365Days } from "../../helpers/dateHelper";

export const initialRevenueExpensesFilterData = {
  dateRange: {
    type: RangeType.Past365Days,
    range: getRange365Days(),
  },
  period: PeriodType.Month,
  groups: [],
  branches: [],
  businessLines: [],
};

export type DashboardState = {
  hasError: boolean;
  loading: LoaderType;
  revenueFilter: RevenueFilter;
  revenueChartLoading: {
    groups: LoaderType;
    groupById: LoaderType;
    groupDetail: LoaderType;
  };
  revenueGroupChartData: FinancialGroupsResponse;
  revenueGroupByIdChartData: FinancialGroupsResponse;
  revenueDetailChartData: FinancialGroupsResponse;
  revenueGroupsOption: Array<{ id: string; name: string }>;
  revenueGroupByIdOption: Array<{ id: string; name: string }>;
  expensesFilter: ExpensesFilter;
  expensesChartLoading: {
    groups: LoaderType;
    groupById: LoaderType;
    groupDetail: LoaderType;
  };
  expensesGroupChartData: FinancialGroupsResponse;
  expensesGroupByIdChartData: FinancialGroupsResponse;
  expensesDetailChartData: FinancialGroupsResponse;
  expensesGroupsOption: Array<{ id: string; name: string }>;
  expensesGroupByIdOption: Array<{ id: string; name: string }>;
  profitLossLoading: LoaderType;
  profitLossData: ProfitLossResponse;

  // old version

  cashFlowInfoLastDateUpdate: string;
  cashFlowInfo: {
    groupedChartData: {
      data: Array<any>;
      labels: Array<string>;
    };
    cashIn: {
      data: Array<any>;
      labels: Array<string>;
    };
  };
  reportalData: {
    financeStanding: Array<ReportalReportItem>;
    workOutcome: Array<ReportalReportItem>;
  };
  frontSideFilter: FrontSideFilter;
  filter: Filter;
  market: MarketData;
  marketWorkOutcomeCompanyInfo: Array<{
    idCode: number;
    orgNameInReport: string;
  }>;
};

const initialState: DashboardState = {
  hasError: false,
  loading: "idle",
  revenueFilter: initialRevenueExpensesFilterData,
  revenueChartLoading: {
    groups: "idle",
    groupById: "idle",
    groupDetail: "idle",
  },
  revenueGroupsOption: [],
  revenueGroupByIdOption: [],
  revenueGroupChartData: { data: [], lastUpdateTime: "" },
  revenueGroupByIdChartData: { data: [], lastUpdateTime: "" },
  revenueDetailChartData: { data: [], lastUpdateTime: "" },
  expensesFilter: initialRevenueExpensesFilterData,
  expensesChartLoading: {
    groups: "idle",
    groupById: "idle",
    groupDetail: "idle",
  },
  expensesGroupsOption: [],
  expensesGroupByIdOption: [],
  expensesGroupChartData: { data: [], lastUpdateTime: "" },
  expensesGroupByIdChartData: { data: [], lastUpdateTime: "" },
  expensesDetailChartData: { data: [], lastUpdateTime: "" },
  profitLossLoading: "idle",
  profitLossData: { data: [], lastUpdateTime: "" },
  // old version

  cashFlowInfoLastDateUpdate: "",
  cashFlowInfo: {
    groupedChartData: { data: [], labels: [] },
    cashIn: { data: [], labels: [] },
  },
  // todo need remove
  reportalData: { financeStanding: [], workOutcome: [] },
  market: {
    identificationCode: null,
    name: null,
    companyCategoryId: null,
    companyFormId: null,
    data: undefined,
  },
  frontSideFilter: {
    group: ChartGroupTypes.Grouped,
    lines: [],
    branches: [],
    groupNames: [],
  },
  filter: {
    periodType: PeriodType.Month,
    fromDate: moment().subtract(1, "year").format(),
    toDate: moment().format(),
  },

  marketWorkOutcomeCompanyInfo: [],
};

export const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState,
  reducers: {
    updateFrontSideFilter: (
      state,
      { payload }: { payload: FrontSideFilter }
    ) => {
      state.frontSideFilter = payload;
    },
    resetGroupeName: (state) => {
      state.frontSideFilter.groupNames = [];
    },
    resetLineAndBranch: (state) => {
      state.frontSideFilter.lines = [];
      state.frontSideFilter.branches = [];
    },
    updateFilter: (state, { payload }: { payload: Filter }) => {
      state.filter = payload;
    },
    updateRevenueFilter: (
      state,
      {
        payload,
      }: {
        payload: RevenueFilter;
      }
    ) => {
      state.revenueFilter = payload;
    },
    updateExpensesFilter: (
      state,
      {
        payload,
      }: {
        payload: ExpensesFilter;
      }
    ) => {
      state.expensesFilter = payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<DashboardState>) => {
    builder
      .addCase(getRevenueGroups.pending, (state) => {
        state.revenueChartLoading.groups = "pending";
      })
      .addCase(
        getRevenueGroups.fulfilled,
        (state, { payload }: { payload: FinancialGroupsResponse }) => {
          state.revenueChartLoading.groups = "succeeded";
          state.revenueGroupsOption = payload.filters || [];
          state.revenueGroupChartData = payload;
        }
      )
      .addCase(getRevenueGroups.rejected, (state) => {
        state.revenueChartLoading.groups = "failed";
        state.revenueGroupsOption = [];
        state.revenueGroupChartData = { data: [], lastUpdateTime: "" };
        state.hasError = true;
      });
    // getRevenueGroupById
    builder
      .addCase(getRevenueGroupById.pending, (state) => {
        state.revenueChartLoading.groupById = "pending";
      })
      .addCase(
        getRevenueGroupById.fulfilled,
        (state, { payload }: { payload: FinancialGroupsResponse }) => {
          state.revenueChartLoading.groupById = "succeeded";
          state.revenueGroupByIdOption = payload.filters || [];
          state.revenueGroupByIdChartData = payload;
        }
      )
      .addCase(getRevenueGroupById.rejected, (state) => {
        state.revenueChartLoading.groupById = "failed";
        state.revenueGroupByIdOption = [];
        state.revenueGroupByIdChartData = { data: [], lastUpdateTime: "" };
        state.hasError = true;
      });
    // getRevenueInDetail
    builder
      .addCase(getRevenueInDetail.pending, (state) => {
        state.revenueChartLoading.groupDetail = "pending";
      })
      .addCase(
        getRevenueInDetail.fulfilled,
        (state, { payload }: { payload: FinancialInDetailResponse }) => {
          state.revenueChartLoading.groupDetail = "succeeded";
          const ordering = processAccountData(payload.data);
          const data: Array<FinancialRecord> = payload.data.map((item) => ({
            id: item.accountId,
            name: item.accountName,
            ordering: ordering[item.accountId],
            period: item.period,
            sumAmount: item.total,
          }));

          state.revenueDetailChartData = {
            data: data,
            lastUpdateTime: payload.lastUpdateTime,
          };
        }
      )
      .addCase(getRevenueInDetail.rejected, (state) => {
        state.revenueChartLoading.groupDetail = "failed";
        state.revenueDetailChartData = { data: [], lastUpdateTime: "" };
        state.hasError = true;
      })

      // getExpensesGroups
      .addCase(getExpensesGroups.pending, (state) => {
        state.expensesChartLoading.groups = "pending";
      })
      .addCase(
        getExpensesGroups.fulfilled,
        (state, { payload }: { payload: FinancialGroupsResponse }) => {
          state.expensesChartLoading.groups = "succeeded";
          state.expensesGroupsOption = payload.filters || [];
          state.expensesGroupChartData = payload;
        }
      )
      .addCase(getExpensesGroups.rejected, (state) => {
        state.expensesChartLoading.groups = "failed";
        state.expensesGroupsOption = [];
        state.expensesGroupChartData = { data: [], lastUpdateTime: "" };
        state.hasError = true;
      })
      // getExpensesGroupById
      .addCase(getExpensesGroupById.pending, (state) => {
        state.expensesChartLoading.groupById = "pending";
      })
      .addCase(
        getExpensesGroupById.fulfilled,
        (state, { payload }: { payload: FinancialGroupsResponse }) => {
          state.expensesChartLoading.groupById = "succeeded";
          state.expensesGroupByIdOption = payload.filters || [];
          state.expensesGroupByIdChartData = payload;
        }
      )
      .addCase(getExpensesGroupById.rejected, (state) => {
        state.expensesChartLoading.groupById = "failed";
        state.expensesGroupByIdOption = [];
        state.expensesGroupByIdChartData = { data: [], lastUpdateTime: "" };
        state.hasError = true;
      })

      // getExpensesInDetail
      .addCase(getExpensesInDetail.pending, (state) => {
        state.expensesChartLoading.groupDetail = "pending";
      })
      .addCase(
        getExpensesInDetail.fulfilled,
        (state, { payload }: { payload: FinancialInDetailResponse }) => {
          state.expensesChartLoading.groupDetail = "succeeded";
          const ordering = processAccountData(payload.data);

          const data: Array<FinancialRecord> = payload.data.map((item) => ({
            id: item.accountId,
            name: item.accountName,
            ordering: ordering[item.accountId],
            period: item.period,
            sumAmount: item.total,
          }));

          state.expensesDetailChartData = {
            data: data,
            lastUpdateTime: payload.lastUpdateTime,
          };
        }
      )
      .addCase(getExpensesInDetail.rejected, (state) => {
        state.expensesChartLoading.groupDetail = "failed";
        state.expensesDetailChartData = { data: [], lastUpdateTime: "" };
        state.hasError = true;
      })

      // getProfitLossInfo
      .addCase(getProfitLossInfo.pending, (state) => {
        state.profitLossLoading = "pending";
      })
      .addCase(
        getProfitLossInfo.fulfilled,
        (state, { payload }: { payload: ProfitLossResponse }) => {
          state.profitLossLoading = "succeeded";
          state.profitLossData = payload;
        }
      )

      .addCase(getProfitLossInfo.rejected, (state) => {
        state.profitLossLoading = "failed";
        state.hasError = true;
      })

      // cashFlowInfo
      .addCase(getCashFlowInfo.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getCashFlowInfo.fulfilled, (state, { payload }) => {
        state.cashFlowInfoLastDateUpdate = payload.lastUpdateTime;

        let groupKeys: Array<string> = [];
        let hasCashIn = false;
        let hasCashOut = false;

        payload.data.forEach((element) => {
          hasCashIn =
            element.cashFlowDirection === CashFlowDirectionType.Received;
          hasCashOut = element.cashFlowDirection === CashFlowDirectionType.Paid;
        });

        if (hasCashIn || hasCashOut) {
          groupKeys = ["Cash In", "Cash Out"];
        }

        if (groupKeys.length) {
          const groupedBarDataForGroup =
            generateCashFlowGroupedChartDataForGroups(
              payload.data as Array<CashFlow>,
              payload.periodType
            );

          const cashIn = generateCashFlowOutcome(
            ["Net Cash Flow"],
            payload.data as Array<CashFlow>,
            payload.periodType
          );
          state.cashFlowInfo.cashIn = cashIn;
          state.cashFlowInfo.groupedChartData = {
            data: groupedBarDataForGroup,
            labels: groupKeys,
          };
        } else {
          state.cashFlowInfo = {
            groupedChartData: { data: [], labels: [] },
            cashIn: { data: [], labels: [] },
          };
        }

        state.loading = "succeeded";
      })
      .addCase(getCashFlowInfo.rejected, (state) => {
        state.loading = "failed";
        state.hasError = true;
      })
      // getReportalReport
      .addCase(getReportalReport.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getReportalReport.fulfilled, (state, { payload }) => {
        state.loading = "succeeded";
        state.reportalData = payload;
      })

      .addCase(getReportalReport.rejected, (state) => {
        state.loading = "failed";
        state.hasError = true;
      })

      // get reportal finance data

      // get Market info
      .addCase(getMarketCompanyInfo.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getMarketCompanyInfo.fulfilled, (state, { payload }) => {
        state.loading = "succeeded";
        state.market = payload;
      })
      .addCase(getMarketCompanyInfo.rejected, (state) => {
        state.loading = "failed";
      })
      // get Market company info
      .addCase(getMarketAllCompanyInfo.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getMarketAllCompanyInfo.fulfilled, (state, { payload }) => {
        state.marketWorkOutcomeCompanyInfo = payload;
        state.loading = "succeeded";
      })
      .addCase(getMarketAllCompanyInfo.rejected, (state) => {
        state.loading = "failed";
      });
  },
});

export const {
  updateFrontSideFilter,
  updateFilter,
  resetLineAndBranch,
  resetGroupeName,
  updateRevenueFilter,
  updateExpensesFilter,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
