import styled from "@emotion/styled";

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px 24px;
`;

export const ChartContainer = styled.div`
  padding: 24px 24px 10px 4px;
`;
