import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Navigate, Outlet, useLocation } from "react-router-dom";

import Alert from "../../components/shared/alert/Alert";

import { AuthURL, PageURL } from "../../constants/router";
import { checkPermissionForRoute } from "../../helpers/helper";
import { isMobile } from "react-device-detect";

import { useAppDispatch, useAppSelector } from "../../store";
import { init } from "../../store/user/userAction";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../store/user/userSlice";
import { Container, Content } from "./App.loyout.style";
import Header from "../../components/desktop/header/Header";
import SideBar from "../../components/desktop/sideBar/SideBar";

const AppLayout = () => {
  const { t } = useTranslation();

  const user = useAppSelector((state) => state.user.user);
  const dispatch = useAppDispatch();

  const selectedCompany = useAppSelector((state) => state.user.selectedCompany);
  const location = useLocation();

  const isShowSuccessMessage = useAppSelector(
    (state) => state.user.showSuccessMessage
  );

  const errorMessage = useAppSelector((state) => state.user.errorMessage);

  useEffect(() => {
    if (isShowSuccessMessage) {
      setTimeout(() => {
        dispatch(showSuccessMessage(false));
      }, 3000);
    }
  }, [isShowSuccessMessage, dispatch]);

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        dispatch(showErrorMessage(""));
      }, 3000);
    }
  }, [errorMessage, dispatch]);

  useEffect(() => {
    if (user && selectedCompany) {
      dispatch(init());
    }
  }, [user, selectedCompany, dispatch]);

  if (!user) {
    return <Navigate to={AuthURL.Root.path} replace />;
  }

  if (!checkPermissionForRoute(location.pathname, PageURL, user.permissions)) {
    // return <Navigate to={PageURL.PermissionDenied.path} replace />;
  }

  return (
    <>
      <Header />
      <Container isMobile={isMobile}>
        {!isMobile && <SideBar />}
        <Content isMobile={isMobile} id="content">
          <Outlet />
          {isShowSuccessMessage && (
            <Alert
              handleClose={() => dispatch(showSuccessMessage(false))}
              status="Success"
              message={t("AppLayout.SuccessMessage")}
            />
          )}
          {errorMessage && (
            <Alert
              handleClose={() => dispatch(showErrorMessage(""))}
              status="Error"
              message={errorMessage}
            />
          )}
        </Content>
        <div id="addMapping"></div>
      </Container>
    </>
  );
};

export default AppLayout;
