import React from "react";
import {
  Header,
  LeftSide,
  MainSection,
  MainSectionItem,
  RightSide,
  SubTitle,
  Title,
  Wrapper,
} from "./SmallCardSkeleton.style";

const SmallCardSkeleton = () => {
  return (
    <Wrapper>
      <Header>
        <LeftSide>
          <Title />
          <SubTitle />
        </LeftSide>
        <RightSide />
      </Header>
      <MainSection>
        <MainSectionItem />
      </MainSection>
    </Wrapper>
  );
};

export default SmallCardSkeleton;
