import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div(
  ({ theme }) => css`
    border-radius: 12px;
    background: ${theme.colors.white};
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.09);

    & span {
      background-color: ${theme.colors.greyShade50};
    }
  `
);
export const Header = styled.div(
  ({ theme }) => css`
    padding: 16px 24px;
    border-bottom: 1px solid ${theme.colors.greyShade50};
    display: flex;
    align-items: center;
    justify-content: space-between;
  `
);
export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const RightSide = styled.span`
  width: 94px;
  height: 18px;
  border-radius: 3px;
`;

export const Title = styled.span`
  width: 124px;
  height: 12px;
  border-radius: 3px;
`;

export const SubTitle = styled.span`
  width: 149px;
  height: 18px;
  border-radius: 4px;
`;

export const MainSection = styled.div`
  padding: 24px;
  display: flex;
`;
export const MainSectionItem = styled.span`
  width: 100%;
  height: 272px;
  border-radius: 8px;
`;
