import { useRef } from "react";
import { Field, Form, Formik, FormikProps } from "formik";
import Modal from "../../../v2/modal/Modal";
import {
  ButtonWrapper,
  ErrorMassage,
  IconWrapper,
  InputWrapper,
  SubTitle,
  Title,
  TitleWrapper,
  Wrapper,
} from "./FinaIntegrationModal.style";
import Input from "../../../v2/input/Input";
import Button from "../../../v2/button/Button";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import Icon from "../../../shared/icon/Icon";

interface FormValues {
  username: string;
  password: string;
  endPointUrl: string;
}
interface FinaIntegrationModalProps {
  title: string;
  loading: boolean;
  initialValue: { username: string; password: string; endPointUrl: string };
  errorMassage: any;
  handleSubmit: (data: FormValues) => void;
  handleClose: () => void;
}

const FinaIntegrationModal = ({
  title,
  loading,
  initialValue,
  errorMassage,
  handleSubmit,
  handleClose,
}: FinaIntegrationModalProps) => {
  const { t } = useTranslation();
  const formikRef = useRef<FormikProps<FormValues> | null>(null);

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
    endPointUrl: Yup.string().required("Password is required"),
  });

  return (
    <Modal
      size="small"
      modalTitle={t("FinaIntegrationModal.ModalTitle")}
      footer={
        <ButtonWrapper>
          <Button
            onClick={() => {
              handleClose();
            }}
            label={t("FinaIntegrationModal.CloseButton")}
            size="small"
            variant="text"
          />
          <Button
            loading={loading}
            onClick={() => {
              formikRef.current?.submitForm();
            }}
            label={t("FinaIntegrationModal.ApplyButton")}
            size="small"
            variant="filled"
          />
        </ButtonWrapper>
      }
      onClose={() => {
        handleClose();
      }}
    >
      <Wrapper isMobile={isMobile}>
        <TitleWrapper>
          <Title>{title}</Title>
          <SubTitle>{t("FinaIntegrationModal.Description")}</SubTitle>
        </TitleWrapper>
        <Formik
          innerRef={formikRef}
          initialValues={initialValue}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {() => (
            <Form>
              <InputWrapper>
                <Field
                  name="username"
                  label={t("FinaIntegrationModal.UserName")}
                  component={Input}
                />
                <Field
                  name="password"
                  type="password"
                  label={t("FinaIntegrationModal.Password")}
                  component={Input}
                />
                <Field
                  name="endPointUrl"
                  label={t("FinaIntegrationModal.EndPointUrl")}
                  component={Input}
                />
                {errorMassage && (
                  <ErrorMassage>
                    <IconWrapper>
                      <Icon
                        fill="white"
                        iconName="close"
                        width={9}
                        height={9}
                      />
                    </IconWrapper>
                    {errorMassage}
                  </ErrorMassage>
                )}
              </InputWrapper>
            </Form>
          )}
        </Formik>
      </Wrapper>
    </Modal>
  );
};

export default FinaIntegrationModal;
