import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../serveces/interceptor";
import { AxiosResponse } from "axios";
import config from "../../utils/config";
import {
  BalanceIntegrationType,
  FinaIntegrationType,
  RsIntegrationType,
} from "./integration.types";

const GET_INTEGRATION_INFO = "GET_INTEGRATION_INFO";

const CREATE_BALANCE_INTEGRATION = "CREATE_BALANCE_INTEGRATION";
const CREATE_RS_INTEGRATION = "CREATE_RS_INTEGRATION";

const CREATE_FINA_INTEGRATION = "CREATE_FINA_INTEGRATION";

const DELETE_RS_INTEGRATION = "DELETE_RS_INTEGRATION";

const CREATE_APRICOT_INTEGRATION = "CREATE_APRICOT_INTEGRATION";

export const getIntegrationList = createAsyncThunk(
  GET_INTEGRATION_INFO,
  async () => {
    const response: AxiosResponse = await axiosInstance.get(
      config.api.endpoints.integration.getIntegrationList
    );

    return response.data;
  }
);

export const createBalanceIntegration = createAsyncThunk(
  CREATE_BALANCE_INTEGRATION,
  async ({ username, password, endPointUrl }: BalanceIntegrationType) => {
    const response: AxiosResponse = await axiosInstance.post(
      config.api.endpoints.integration.balanceIntegration,
      {
        username,
        password,
        endPointUrl,
      }
    );

    return response.data;
  }
);

export const createRsIntegration = createAsyncThunk(
  CREATE_RS_INTEGRATION,
  async ({ UserName, Password }: RsIntegrationType) => {
    const response: AxiosResponse = await axiosInstance.put(
      config.api.endpoints.integration.rsIntegration,
      {
        UserName,
        Password,
      }
    );

    return response.data;
  }
);

export const createFinaIntegration = createAsyncThunk(
  CREATE_FINA_INTEGRATION,
  async ({ username, password, endPointUrl }: FinaIntegrationType) => {
    const response: AxiosResponse = await axiosInstance.post(
      config.api.endpoints.integration.finaIntegration,
      {
        username,
        password,
        endPointUrl,
      }
    );

    return response.data;
  }
);

export const deleteRsIntegration = createAsyncThunk(
  DELETE_RS_INTEGRATION,
  async () => {
    await axiosInstance.delete(
      config.api.endpoints.integration.deleteRsIntegration
    );
  }
);

export const createApricotIntegration = createAsyncThunk(
  CREATE_APRICOT_INTEGRATION,
  async ({
    username,
    password,
    endPointUrl,
    dbName,
  }: {
    username: string;
    password: string;
    endPointUrl: string;
    dbName: string;
  }) => {
    const response: AxiosResponse = await axiosInstance.post(
      config.api.endpoints.integration.apricotIntegration,
      {
        username,
        password,
        endPointUrl,
        dbName,
      }
    );
    return response.data;
  }
);
