import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { UnMappedItem } from "../../../../../../store/mapping/mapping.types";
import Button from "../../../../../v2/button/Button";
import Input from "../../../../../v2/input/Input";
import Select from "../../../../../v2/select/Select";
import {
  ButtonWrapper,
  ContentTitle,
  InputWrapper,
  Wrapper,
} from "./ItemInfo.style";
import { InfoFormValues } from "../../AddMappingItem";

const validationSchema = Yup.object().shape({
  accountId: Yup.string().required("Account ID is required"),
  name: Yup.string().required("Account Name is required"),
});

interface MappingItemInfoProps {
  handleNext: (formValues: InfoFormValues) => void;
  initialValue: InfoFormValues;
  unMappedItem: Array<UnMappedItem>;
  portalMappingOptions: Array<{ value: string; label: string }>;
  companyBranchesOptions: Array<{ value: string; label: string }>;
  companyBusinessLineOptions: Array<{ value: string; label: string }>;
  cashFlowReceiveOptions: Array<{ value: string; label: string }>;
  cashFlowPaidOptions: Array<{ value: string; label: string }>;
}
const ItemInfo = ({
  initialValue,
  unMappedItem,
  portalMappingOptions,
  companyBranchesOptions,
  companyBusinessLineOptions,
  cashFlowReceiveOptions,
  cashFlowPaidOptions,
  handleNext,
}: MappingItemInfoProps) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={initialValue}
      validationSchema={validationSchema}
      onSubmit={handleNext}
    >
      {({ setFieldValue, errors, touched, values }) => (
        <Form>
          <Wrapper>
            <InputWrapper>
              <Field
                inputMode
                name="accountId"
                label={t("AddMapping.AccountIdLabel")}
                selectedValue={values.accountId}
                options={unMappedItem.map((item) => ({
                  value: item.accountId,
                  label: `${item.accountId} - ${item.name}`,
                }))}
                component={Select}
                hasError={errors.accountId && touched.accountId}
                onChange={(value: string) => {
                  const accountName = unMappedItem.find(
                    (item) => item.accountId === value
                  );
                  setFieldValue("name", accountName?.name);
                }}
              />
              <Field
                name="name"
                label={t("AddMapping.NameFieldLabel")}
                hasError={errors.name && touched.name}
                component={Input}
              />
            </InputWrapper>
            <InputWrapper>
              <ContentTitle>
                {t("AddMapping.SARASLineElementTitle")}
              </ContentTitle>
              <Field
                name="debitRePortalMappingId"
                label={t("AddMapping.DebitRePortalLabel")}
                options={portalMappingOptions}
                onChange={(value: string) => {
                  if (!values.creditRePortalMappingId) {
                    setFieldValue("creditRePortalMappingId", value);
                  }
                }}
                component={Select}
              />
              <Field
                name="creditRePortalMappingId"
                label={t("AddMapping.CreditRePortalLabel")}
                options={portalMappingOptions}
                component={Select}
              />
            </InputWrapper>
            {Boolean(
              companyBranchesOptions.length ||
                Boolean(companyBusinessLineOptions.length)
            ) && (
              <InputWrapper>
                <ContentTitle>
                  {t("AddMapping.CompanyInformationTitle")}
                </ContentTitle>
                {Boolean(companyBranchesOptions.length) && (
                  <Field
                    name="companyBranchId"
                    label={t("AddMapping.CompanyBranchTitle")}
                    options={companyBranchesOptions}
                    component={Select}
                  />
                )}
                {Boolean(companyBusinessLineOptions.length) && (
                  <Field
                    name="companyBusinessLineId"
                    label={t("AddMapping.CompanyBusinessLineLabel")}
                    options={companyBusinessLineOptions}
                    component={Select}
                  />
                )}
              </InputWrapper>
            )}
            <InputWrapper>
              <ContentTitle>{t("AddMapping.AmountCategoryTitle")}</ContentTitle>
              <Field
                reversed
                name="accountMappingCashFlowCashInId"
                label={t("AddMapping.ReceivedAmountTitle")}
                options={cashFlowReceiveOptions}
                component={Select}
              />
              <Field
                reversed
                name="accountMappingCashFlowCashOutId"
                label={t("AddMapping.PaidAmountTitle")}
                options={cashFlowPaidOptions}
                component={Select}
              />
            </InputWrapper>
          </Wrapper>
          <ButtonWrapper>
            <Button
              type="submit"
              label={t("AddMapping.NextStep")}
              size="large"
              variant="filled"
              disabled={!values.accountId || !values.name}
            />
          </ButtonWrapper>
        </Form>
      )}
    </Formik>
  );
};

export default ItemInfo;
