import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { PeriodType } from "../../../../../enums/enum";

import { getExpenseByCounterPartyAndPeriod } from "../../../../../store/report/reportAction";
import moment from "moment";
import ReportFilter from "../../../../../components/desktop/reportFilter/ReportFilter";
import Loader from "../../../../../components/shared/loader/Loader";
import {
  CustomTable,
  LoaderWrapper,
  NoResult,
  TableRow,
  TableRowItem,
  TableRowItemId,
  TableRowItemName,
} from "./partyAndPeriod.style";
import TableHeader from "../../../../../components/shared/tableHeader/TableHeader";
import { formatNumber } from "../../../../../helpers/dashboardHelper";
import { useTranslation } from "react-i18next";

const PartyAndPeriod = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const expensesCounterPartyAndPeriod = useAppSelector(
    (state) => state.report.expensesCounterPartyAndPeriod
  );
  const loading = useAppSelector((state) => state.report.loading);
  const selectedCompany = useAppSelector((state) => state.user.selectedCompany);
  const [period, setPeriod] = useState(PeriodType.Month);

  const [dateRange, setDateRange] = useState({
    fromDate: moment().subtract(1, "year").format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
  });
  useEffect(() => {
    dispatch(
      getExpenseByCounterPartyAndPeriod({
        fromDate: dateRange.fromDate,
        toDate: dateRange.toDate,
        PeriodType: period,
      })
    );
  }, [dateRange, selectedCompany]);

  const submit = ({
    fromDate,
    toDate,
    periodType,
  }: {
    fromDate?: string;
    toDate?: string;
    periodType?: PeriodType;
  }) => {
    if (periodType) {
      setPeriod(periodType);
    }
    if (fromDate && toDate) {
      setDateRange({
        fromDate: moment(fromDate).format("YYYY-MM-DD"),
        toDate: moment(toDate).format("YYYY-MM-DD"),
      });
    }
  };
  return (
    <>
      <ReportFilter dates={dateRange} handleSubmit={submit} />
      {loading === "pending" ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        <>
          {expensesCounterPartyAndPeriod.data.length ? (
            <CustomTable>
              <TableHeader headerNames={expensesCounterPartyAndPeriod.labels} />
              <tbody>
                {expensesCounterPartyAndPeriod.data.map((item, index) => (
                  <TableRow key={index}>
                    <TableRowItemId>{item.debitAccountId}</TableRowItemId>
                    <TableRowItemName>{item.debitAccountName}</TableRowItemName>
                    {item.periodTotals.map((item, index) => (
                      <TableRowItem key={index}>
                        {formatNumber(item.total)}
                      </TableRowItem>
                    ))}
                    <TableRowItem>{formatNumber(+item.total)}</TableRowItem>
                  </TableRow>
                ))}
              </tbody>
            </CustomTable>
          ) : (
            <NoResult>{t("EmployeeByStaffAndPeriod.NoResult")}</NoResult>
          )}
        </>
      )}
    </>
  );
};

export default PartyAndPeriod;
