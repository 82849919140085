import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../../../../store";
import { PeriodType } from "../../../../../enums/enum";

import { getEmployeeRemunerationAndCostItemsReport } from "../../../../../store/report/reportAction";
import moment from "moment";
import ReportFilter from "../../../../../components/desktop/reportFilter/ReportFilter";
import Loader from "../../../../../components/shared/loader/Loader";
import {
  CustomTable,
  LoaderWrapper,
  NoResult,
  TableRow,
  TableRowItem,
  TableRowItemId,
  TableRowItemName,
} from "./EmployRemuneration.style";
import TableHeader from "../../../../../components/shared/tableHeader/TableHeader";
import { formatNumber } from "../../../../../helpers/dashboardHelper";

const EmployeeRemuneration = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.report.employeeRemuneration);
  const loading = useAppSelector((state) => state.report.loading);
  const selectedCompany = useAppSelector((state) => state.user.selectedCompany);

  const [dateRange, setDateRange] = useState({
    fromDate: moment().subtract(1, "year").format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
  });

  const submit = ({
    fromDate,
    toDate,
  }: {
    fromDate?: string;
    toDate?: string;
    periodType?: PeriodType;
  }) => {
    if (fromDate && toDate) {
      setDateRange({
        fromDate: moment(fromDate).format("YYYY-MM-DD"),
        toDate: moment(toDate).format("YYYY-MM-DD"),
      });
    }
  };

  useEffect(() => {
    dispatch(
      getEmployeeRemunerationAndCostItemsReport({
        fromDate: dateRange.fromDate,
        toDate: dateRange.toDate,
      })
    );
  }, [dateRange, selectedCompany]);

  return (
    <>
      <ReportFilter dates={dateRange} handleSubmit={submit} />
      {loading === "pending" ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        <>
          {data.items.length ? (
            <>
              <CustomTable>
                <TableHeader
                  headerNames={[
                    "EmployRemuneration.ClientId",
                    "EmployRemuneration.ClientName",
                    ...data.headers,
                  ]}
                />
                <tbody>
                  {data.items.map((item) => (
                    <TableRow key={item.clientAccountId}>
                      <TableRowItemId>{item.clientAccountId}</TableRowItemId>
                      <TableRowItemName>
                        {item.clientAccountName}
                      </TableRowItemName>
                      {item.amounts.map((amount, index) => (
                        <TableRowItem key={index}>
                          {formatNumber(amount)}
                        </TableRowItem>
                      ))}
                    </TableRow>
                  ))}
                </tbody>
              </CustomTable>
            </>
          ) : (
            <NoResult>{t("EmployRemuneration.NoResult")}</NoResult>
          )}
        </>
      )}
    </>
  );
};

export default EmployeeRemuneration;
