import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  IntegrationContainer,
  IntegrationTitle,
  IntegrationWrapper,
  Title,
  Wrapper,
} from "./Integrations.style";

import { useAppDispatch, useAppSelector } from "../../../store";
import {
  createApricotIntegration,
  createBalanceIntegration,
  createFinaIntegration,
  createRsIntegration,
  deleteRsIntegration,
  getIntegrationList,
} from "../../../store/integration/integrationAction";

import IntegrationItem from "../../../components/desktop/integration/integrationItem/IntegrationItem";
import {
  ApricotIntegrationType,
  BalanceIntegrationType,
  FinaIntegrationType,
  RsIntegrationType,
} from "../../../store/integration/integration.types";
import BalanceIntegrationModal from "../../../components/desktop/integration/balanceIntegrationModal/BalanceIntegrationModal";
import RsIntegrationModal from "../../../components/desktop/integration/rsIntegrationModal/RsIntegrationModal";
import DeleteConfirmationModal from "../../../components/desktop/mapping/deleteConfirmationModal/DeleteConfirmationModal";
import { ActionType } from "../../../store/type";
import IntegrationSkeleton from "../../../components/desktop/integration/integrationSkeleton/IntegrationSkeleton";
import SuccessModal from "../../../components/desktop/integration/successModal/SuccessModal";
import { isMobile } from "react-device-detect";
import ApricotIntegrationModal from "../../../components/desktop/integration/apricotIntegrationModal/ApricotIntegrationModal";
import FinaIntegrationModal from "../../../components/desktop/integration/finaIntegrationModal/FinaIntegrationModal";

export type IntegrationDataType = {
  title: string;
  description: string;
  image: string;
  isConnected: any;
};

enum IntegrationItems {
  IDLE = "Idle",
  BALANCE = "Balance",
  RS = "Rs",
  APRICOT = "APRICOT",
  FINA = "FINA",
}

enum ModalMode {
  IDLE = "Idle",
  CREATE = "Create",
  EDIT = "Edit",
  SUCCESS = "Success",
  CANCEL = "Cancel",
}

const Integrations = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [errorMassage, setErrorMassage] = useState("");

  const integration = useAppSelector(
    (state) => state.integration.integrationData
  );

  const loadStatus = useAppSelector((state) => state.integration.loading);

  const selectedCompany = useAppSelector((state) => state.user.selectedCompany);

  const [openIntegrationModal, setOpenIntegrationModal] = useState({
    type: IntegrationItems.IDLE,
    mode: ModalMode.IDLE,
  });

  useEffect(() => {
    dispatch(getIntegrationList());
  }, [dispatch, selectedCompany]);

  const handleCreateBalanceIntegration = (values: BalanceIntegrationType) => {
    setErrorMassage("");
    dispatch(createBalanceIntegration(values)).then(({ error }: ActionType) => {
      if (error) {
        setErrorMassage(error.message || "");
      } else {
        setOpenIntegrationModal((prev) => ({
          mode: ModalMode.SUCCESS,
          type: prev.type,
        }));
      }
    });
  };

  const handleCreateApricotIntegration = (values: ApricotIntegrationType) => {
    setErrorMassage("");
    dispatch(createApricotIntegration(values)).then(({ error }: ActionType) => {
      if (error) {
        setErrorMassage(error.message || "");
      } else {
        setOpenIntegrationModal((prev) => ({
          mode: ModalMode.SUCCESS,
          type: prev.type,
        }));
      }
    });
  };

  const handleSuccessClose = () => {
    setOpenIntegrationModal({
      type: IntegrationItems.IDLE,
      mode: ModalMode.IDLE,
    });
    dispatch(getIntegrationList());
  };

  const handleCreateRsIntegration = (values: RsIntegrationType) => {
    setErrorMassage("");
    dispatch(createRsIntegration(values)).then(({ error }: ActionType) => {
      if (error) {
        setErrorMassage(error.message || "");
      } else {
        setOpenIntegrationModal((prev) => ({
          mode: ModalMode.SUCCESS,
          type: prev.type,
        }));
      }
    });
  };

  const handleCreateFinaIntegration = (values: FinaIntegrationType) => {
    setErrorMassage("");
    dispatch(createFinaIntegration(values)).then(({ error }: ActionType) => {
      if (error) {
        setErrorMassage(error.message || "");
      } else {
        setOpenIntegrationModal((prev) => ({
          mode: ModalMode.SUCCESS,
          type: prev.type,
        }));
      }
    });
  };

  const handleCancel = () => {
    if (
      openIntegrationModal.type === IntegrationItems.RS &&
      openIntegrationModal.mode === ModalMode.CANCEL
    )
      dispatch(deleteRsIntegration()).then(() => {
        dispatch(getIntegrationList());
        setOpenIntegrationModal({
          type: IntegrationItems.IDLE,
          mode: ModalMode.IDLE,
        });
      });
  };

  return (
    <>
      {loadStatus.list === "pending" ? (
        <IntegrationSkeleton />
      ) : (
        <Wrapper isMobile={isMobile}>
          <Title isMobile={isMobile}>{t("Integration.Title")}</Title>
          <IntegrationWrapper isMobile={isMobile}>
            {(Boolean(integration?.balanceCredential.data) ||
              Boolean(integration?.apricotServiceCredential.data) ||
              Boolean(integration?.finaServiceCredential.data) ||
              Boolean(integration?.revenueServiceCredential.data)) && (
              <IntegrationContainer>
                <IntegrationTitle isMobile={isMobile}>
                  {t("Integration.ActiveTitle")}
                </IntegrationTitle>
                {Boolean(integration?.revenueServiceCredential.data) && (
                  <IntegrationItem
                    logo="rsLogo"
                    title={t("Integration.RsTitle")}
                    description={t("Integration.RsDescription")}
                    hasCancellation={
                      integration?.revenueServiceCredential.hasCancellation
                    }
                    isConnected={Boolean(
                      integration?.revenueServiceCredential.data
                    )}
                    isValid={
                      integration?.revenueServiceCredential.data
                        ?.isCredentialsValid
                    }
                    handleEdit={() => {
                      setOpenIntegrationModal({
                        type: IntegrationItems.RS,
                        mode: ModalMode.EDIT,
                      });
                    }}
                    handleCancel={() => {
                      setOpenIntegrationModal({
                        type: IntegrationItems.RS,
                        mode: ModalMode.CANCEL,
                      });
                    }}
                  />
                )}
                {Boolean(integration?.apricotServiceCredential.data) && (
                  <IntegrationItem
                    logo="apricot"
                    title={t("Integration.ApricotTitle")}
                    description={t("Integration.ApricotDescription")}
                    hasCancellation={
                      integration?.apricotServiceCredential.hasCancellation
                    }
                    isConnected={Boolean(
                      integration?.apricotServiceCredential.data
                    )}
                    isValid={
                      integration?.apricotServiceCredential.data
                        ?.isCredentialsValid
                    }
                    handleEdit={() => {
                      setOpenIntegrationModal({
                        type: IntegrationItems.APRICOT,
                        mode: ModalMode.EDIT,
                      });
                    }}
                    handleCancel={() => {
                      setOpenIntegrationModal({
                        type: IntegrationItems.APRICOT,
                        mode: ModalMode.CANCEL,
                      });
                    }}
                  />
                )}
                {Boolean(integration?.balanceCredential.data) && (
                  <IntegrationItem
                    logo="balanceLogo"
                    title={t("Integration.BalanceTitle")}
                    description={t("Integration.BalanceDescription")}
                    hasCancellation={
                      integration?.balanceCredential.hasCancellation
                    }
                    isValid={
                      integration?.balanceCredential.data?.isCredentialsValid
                    }
                    isConnected={Boolean(integration?.balanceCredential.data)}
                    handleEdit={() => {
                      setOpenIntegrationModal({
                        type: IntegrationItems.BALANCE,
                        mode: ModalMode.EDIT,
                      });
                    }}
                  />
                )}

                {Boolean(integration?.finaServiceCredential.data) && (
                  <IntegrationItem
                    logo="burger"
                    title={t("Integration.FinaTitle")}
                    description={t("Integration.FinaDescription")}
                    hasCancellation={
                      integration?.finaServiceCredential.hasCancellation
                    }
                    isValid={
                      integration?.finaServiceCredential.data
                        ?.isCredentialsValid
                    }
                    isConnected={Boolean(
                      integration?.finaServiceCredential.data
                    )}
                    handleEdit={() => {
                      setOpenIntegrationModal({
                        type: IntegrationItems.FINA,
                        mode: ModalMode.EDIT,
                      });
                    }}
                  />
                )}
              </IntegrationContainer>
            )}
            {(Boolean(
              integration?.revenueServiceCredential.isSupported &&
                !integration?.revenueServiceCredential.data
            ) ||
              Boolean(
                integration?.balanceCredential.isSupported &&
                  !integration?.revenueServiceCredential.data
              ) ||
              Boolean(
                integration?.finaServiceCredential.isSupported &&
                  !integration?.finaServiceCredential.data
              )) && (
              <IntegrationContainer>
                <IntegrationTitle isMobile={isMobile}>
                  {t("Integration.IntegrableProgramsTitle")}
                </IntegrationTitle>
                {Boolean(
                  integration?.revenueServiceCredential.isSupported &&
                    !integration?.revenueServiceCredential.data
                ) && (
                  <IntegrationItem
                    logo="rsLogo"
                    title={t("Integration.RsTitle")}
                    description={t("Integration.RsDescription")}
                    isConnected={false}
                    handleCreate={() => {
                      setOpenIntegrationModal({
                        type: IntegrationItems.RS,
                        mode: ModalMode.CREATE,
                      });
                    }}
                  />
                )}
                {Boolean(
                  integration?.apricotServiceCredential.isSupported &&
                    !integration?.apricotServiceCredential.data
                ) && (
                  <IntegrationItem
                    logo="apricot"
                    title={t("Integration.ApricotTitle")}
                    description={t("Integration.ApricotDescription")}
                    isConnected={false}
                    handleCreate={() => {
                      setOpenIntegrationModal({
                        type: IntegrationItems.APRICOT,
                        mode: ModalMode.CREATE,
                      });
                    }}
                  />
                )}
                {Boolean(
                  integration?.balanceCredential.isSupported &&
                    !integration?.balanceCredential.data
                ) && (
                  <IntegrationItem
                    logo="balanceLogo"
                    title={t("Integration.BalanceTitle")}
                    description={t("Integration.BalanceDescription")}
                    isConnected={false}
                    handleCreate={() => {
                      setOpenIntegrationModal({
                        type: IntegrationItems.BALANCE,
                        mode: ModalMode.CREATE,
                      });
                    }}
                  />
                )}
                {Boolean(
                  integration?.finaServiceCredential.isSupported &&
                    !integration?.finaServiceCredential.data
                ) && (
                  <IntegrationItem
                    logo="burger"
                    title={t("Integration.FinaTitle")}
                    description={t("Integration.FinaDescription")}
                    isConnected={false}
                    handleCreate={() => {
                      setOpenIntegrationModal({
                        type: IntegrationItems.FINA,
                        mode: ModalMode.CREATE,
                      });
                    }}
                  />
                )}
              </IntegrationContainer>
            )}
          </IntegrationWrapper>
        </Wrapper>
      )}

      {openIntegrationModal.type === IntegrationItems.APRICOT &&
        (openIntegrationModal.mode === ModalMode.EDIT ||
          openIntegrationModal.mode === ModalMode.CREATE) && (
          <ApricotIntegrationModal
            loading={loadStatus.apricot === "pending"}
            errorMassage={errorMassage}
            title={t("Integration.ApricotIntegration")}
            initialValue={
              openIntegrationModal.mode === ModalMode.EDIT
                ? {
                    username:
                      integration?.apricotServiceCredential.data?.username ||
                      "",
                    password: "",
                    endPointUrl:
                      integration?.apricotServiceCredential.data?.endPointUrl ||
                      "",
                    dbName:
                      integration?.apricotServiceCredential.data?.dbName || "",
                  }
                : {
                    username: "",
                    password: "",
                    endPointUrl: "",
                    dbName: "",
                  }
            }
            handleSubmit={handleCreateApricotIntegration}
            handleClose={() => {
              setErrorMassage("");
              setOpenIntegrationModal({
                type: IntegrationItems.IDLE,
                mode: ModalMode.IDLE,
              });
            }}
          />
        )}

      {openIntegrationModal.type === IntegrationItems.BALANCE &&
        (openIntegrationModal.mode === ModalMode.EDIT ||
          openIntegrationModal.mode === ModalMode.CREATE) && (
          <BalanceIntegrationModal
            loading={loadStatus.balance === "pending"}
            errorMassage={errorMassage}
            title={t("Integration.BalanceTitle")}
            initialValue={
              openIntegrationModal.mode === ModalMode.EDIT
                ? {
                    username:
                      integration?.balanceCredential.data?.username || "",
                    password: "",
                    endPointUrl:
                      integration?.balanceCredential.data?.endPointUrl || "",
                  }
                : {
                    username: "",
                    password: "",
                    endPointUrl: "",
                  }
            }
            handleSubmit={handleCreateBalanceIntegration}
            handleClose={() => {
              setErrorMassage("");
              setOpenIntegrationModal({
                type: IntegrationItems.IDLE,
                mode: ModalMode.IDLE,
              });
            }}
          />
        )}
      {openIntegrationModal.type === IntegrationItems.RS &&
        (openIntegrationModal.mode === ModalMode.EDIT ||
          openIntegrationModal.mode === ModalMode.CREATE) && (
          <RsIntegrationModal
            loading={loadStatus.rs === "pending"}
            errorMassage={errorMassage}
            title={t("Integration.RsTitle")}
            initialValue={
              openIntegrationModal.mode === ModalMode.EDIT
                ? {
                    UserName:
                      integration?.revenueServiceCredential.data
                        ?.serviceAccountUsername || "",
                    Password: "",
                  }
                : {
                    UserName: "",
                    Password: "",
                  }
            }
            handleSubmit={handleCreateRsIntegration}
            handleClose={() => {
              setErrorMassage("");
              setOpenIntegrationModal({
                type: IntegrationItems.IDLE,
                mode: ModalMode.IDLE,
              });
            }}
          />
        )}

      {openIntegrationModal.type === IntegrationItems.FINA &&
        (openIntegrationModal.mode === ModalMode.EDIT ||
          openIntegrationModal.mode === ModalMode.CREATE) && (
          <FinaIntegrationModal
            loading={loadStatus.rs === "pending"}
            errorMassage={errorMassage}
            title={t("Integration.FinaTitle")}
            initialValue={
              openIntegrationModal.mode === ModalMode.EDIT
                ? {
                    username:
                      integration?.finaServiceCredential.data?.username || "",
                    password: "",
                    endPointUrl:
                      integration?.finaServiceCredential.data?.endPointUrl ||
                      "",
                  }
                : {
                    username: "",
                    password: "",
                    endPointUrl: "",
                  }
            }
            handleSubmit={handleCreateFinaIntegration}
            handleClose={() => {
              setErrorMassage("");
              setOpenIntegrationModal({
                type: IntegrationItems.IDLE,
                mode: ModalMode.IDLE,
              });
            }}
          />
        )}

      {(openIntegrationModal.type === IntegrationItems.RS ||
        openIntegrationModal.type === IntegrationItems.FINA ||
        openIntegrationModal.type === IntegrationItems.APRICOT ||
        openIntegrationModal.type === IntegrationItems.BALANCE) &&
        openIntegrationModal.mode === ModalMode.CANCEL && (
          <DeleteConfirmationModal
            name={`${t("Integration.CancellationModalTitle")} ${
              openIntegrationModal.type
            }`}
            handleClose={() =>
              setOpenIntegrationModal({
                type: IntegrationItems.IDLE,
                mode: ModalMode.IDLE,
              })
            }
            handleClick={handleCancel}
          />
        )}

      {(openIntegrationModal.type === IntegrationItems.RS ||
        openIntegrationModal.type === IntegrationItems.FINA ||
        openIntegrationModal.type === IntegrationItems.APRICOT ||
        openIntegrationModal.type === IntegrationItems.BALANCE) &&
        openIntegrationModal.mode === ModalMode.SUCCESS && (
          <SuccessModal
            title={t("Integration.SuccessModalTitle")}
            subTitle={t("Integration.SuccessModalSubTitle")}
            handleClose={handleSuccessClose}
          />
        )}
    </>
  );
};

export default Integrations;
