import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import {
  Overlay,
  SideBarWrapper,
  Title,
  TitleWrapper,
  Wrapper,
} from "./BottomSheet.style";
import NavigationMobile from "../navigation/NavigationMobile";

import AdministrationMobile from "../administration/AdministrationMobile";
import IconButtonMobile from "../iconButton/IconButtonMobile";

import { useAppDispatch, useAppSelector } from "../../../store";
import {
  chooseCompany,
  updateDefaultCompany,
} from "../../../store/user/userAction";
import { selectCompany, updateLanguage } from "../../../store/user/userSlice";

import ChartFilterMobile from "../chartFilter/ChartFilterMobile";
import SelectChartTypeMobile from "../selectChartType/SelectChartTypeMobile";
import DateRangeMobile from "./dateRangePicker/DateRangeMobile";
import {
  BottomSheetType,
  ChartGroupTypes,
  Languages,
  PeriodType,
  RangeType,
} from "../../../enums/enum";
import SelectDateTypeMobile from "../selectDateType/SelectDateTypeMobile";

import { FrontSideFilter } from "../../../store/dashboard/dashboard.types";
import SelectBranchMobile from "../selectBranch/SelectBranchMobile";
import SelectBusinessLineMobile from "../selectBusinessLine/SelectBusinessLineMobile";
import { resetLineAndBranch } from "../../../store/dashboard/dashboardSlice";
import SelectGroupName from "../selectGroupName/SelectGroupName";
import SwitchCompanyMobile from "../switchCompany/SwitchCompanyMobile";
import PersonalInfo from "./personalInfo/PersonalInfo";
import Icon from "../../shared/icon/Icon";

interface BottomSheetProps {
  filter: { periodType: PeriodType; fromDate: string; toDate: string };
  isChanged: {
    periodType: boolean;
    date: boolean;
    group: boolean;
    line: boolean;
    branch: boolean;
    groupName: boolean;
  };
  frontSideFilter: FrontSideFilter;
  filterRangeType: RangeType;
  filterLines: Array<{ value: string; label: string }>;
  filterBranches: Array<{ value: string; label: string }>;
  filterGroupNames: Array<{ value: string; label: string }>;
  filterGroupType: ChartGroupTypes;
  openBottomSheet: BottomSheetType;
  showViewFilter: boolean;
  pageLocation: "Revenue" | "Expenses" | "";
  showBranchAndLineFilter: boolean;
  handleChangeDataRange: (type: RangeType) => void;
  handleChangeViewType: (type: ChartGroupTypes) => void;
  handleUpdateDate: (fromDate: string, toDate: string) => void;
  handleChangeDateType: (type: PeriodType) => void;
  handleLine: (lines: Array<{ value: string; label: string }>) => void;
  handleGroupName: (names: Array<{ value: string; label: string }>) => void;
  handleBranch: (branches: Array<{ value: string; label: string }>) => void;
  handleFilterApply: () => void;
  handleClick: (type: BottomSheetType) => void;
  handleClose: () => void;
}
function disableScroll() {
  document.documentElement.classList.add("no-scroll");
  document.body.classList.add("no-scroll");
}

function enableScroll() {
  document.documentElement.classList.remove("no-scroll");
  document.body.classList.remove("no-scroll");
}

const BottomSheet = ({
  filter,
  isChanged,
  frontSideFilter,
  filterLines,
  filterBranches,
  filterGroupNames,
  filterRangeType,
  filterGroupType,
  pageLocation,
  openBottomSheet,
  showViewFilter,
  showBranchAndLineFilter,
  handleChangeDataRange,
  handleChangeViewType,
  handleUpdateDate,
  handleChangeDateType,
  handleBranch,
  handleLine,
  handleGroupName,
  handleFilterApply,
  handleClose,
  handleClick,
}: BottomSheetProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const clickBottomSheet = useRef<HTMLDivElement>(null);

  const selectedCompany = useAppSelector((state) => state.user.selectedCompany);

  const defaultCompanyId = useAppSelector(
    (state) => state.user.user?.defaultCompanyId
  );

  // const revenueGroupFilterOptions = useAppSelector(
  //   (state) => state.dashboard.revenueGroupFilterOptions
  // );

  // const expensesGroupFilterOptions = useAppSelector(
  //   (state) => state.dashboard.expensesGroupFilterOptions
  // );

  const companyBranches = useAppSelector(
    (state) => state.company.companyBranch
  ).map((item) => ({ value: item.id.toString(), label: item.name }));

  const companyBusinessLine = useAppSelector(
    (state) => state.company.companyBusinessLine
  ).map((item) => ({ value: item.id.toString(), label: item.name }));

  useEffect(() => {
    if (openBottomSheet === BottomSheetType.IDLE) {
      enableScroll();
    } else {
      disableScroll();
    }
    return () => {
      enableScroll();
    };
  }, [openBottomSheet]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const modalElement = document.querySelector(".rs-picker-daterange-panel");
      if (
        clickBottomSheet?.current &&
        !clickBottomSheet.current.contains(event.target as Node) &&
        (!modalElement || !modalElement.contains(event.target as Node))
      ) {
        setTimeout(() => {
          handleClose();
        }, 0);
      }
    };

    if (clickBottomSheet?.current) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [clickBottomSheet, handleClose]);

  const handleSelectDefault = (id: number | null): void => {
    dispatch(updateDefaultCompany({ defaultCompanyId: id }));
  };

  const handleSelectCompany = (companyId: number) => {
    dispatch(chooseCompany({ CompanyId: companyId })).then(() => {
      dispatch(selectCompany({ selectedCompanyId: companyId }));
      dispatch(resetLineAndBranch());
    });
  };

  const handleUpdateLanguage = (lang: Languages) => {
    dispatch(updateLanguage(lang));
  };

  return (
    <div>
      {openBottomSheet !== BottomSheetType.NAVIGATION && <Overlay />}
      {openBottomSheet === BottomSheetType.NAVIGATION && (
        <SideBarWrapper ref={clickBottomSheet} open={openBottomSheet}>
          <NavigationMobile
            handleClose={handleClose}
            handleChangeLanguage={handleUpdateLanguage}
          />
        </SideBarWrapper>
      )}
      {openBottomSheet === BottomSheetType.SWITCH_ACCOUNT && (
        <Wrapper ref={clickBottomSheet} open={openBottomSheet}>
          <TitleWrapper>
            <Title>{t("BottomSheetV2.ChooseCompany")}</Title>
            <IconButtonMobile handleClick={handleClose}>
              <Icon iconName="closeIcon" />
            </IconButtonMobile>
          </TitleWrapper>
          <SwitchCompanyMobile
            selectedCompany={selectedCompany}
            defaultCompany={defaultCompanyId}
            handleSelectDefault={handleSelectDefault}
            handleSelect={handleSelectCompany}
            handleClose={handleClose}
          />
        </Wrapper>
      )}
      {openBottomSheet === BottomSheetType.ADMINISTRATION && (
        <Wrapper ref={clickBottomSheet} open={openBottomSheet}>
          <TitleWrapper>
            <Title>{t("BottomSheet.AdministrationTitle")}</Title>
            <IconButtonMobile handleClick={handleClose}>
              <Icon iconName="closeIcon" />
            </IconButtonMobile>
          </TitleWrapper>
          <AdministrationMobile handleClose={handleClose} />
        </Wrapper>
      )}

      {openBottomSheet === BottomSheetType.PERSONAL_INFO && (
        <Wrapper ref={clickBottomSheet} open={openBottomSheet}>
          <TitleWrapper>
            <Title>{t("BottomSheetV2.PersonalProfile")}</Title>
            <IconButtonMobile handleClick={handleClose}>
              <Icon iconName="closeIcon" />
            </IconButtonMobile>
          </TitleWrapper>
          <PersonalInfo handleClose={handleClose} />
        </Wrapper>
      )}
      {openBottomSheet === BottomSheetType.FILTER && (
        <Wrapper ref={clickBottomSheet} open={openBottomSheet}>
          <TitleWrapper>
            <Title>{t("BottomSheet.FilterTitle")}</Title>
            <IconButtonMobile handleClick={handleClose}>
              <Icon iconName="closeIcon" />
            </IconButtonMobile>
          </TitleWrapper>
          <ChartFilterMobile
            filter={filter}
            isChanged={isChanged}
            showViewFilter={showViewFilter}
            companyBusinessLine={companyBusinessLine}
            companyBranches={companyBranches}
            showBranchFilter={Boolean(
              showBranchAndLineFilter && companyBranches.length
            )}
            showBusinessLineFilter={Boolean(
              showBranchAndLineFilter && companyBusinessLine.length
            )}
            chartGroupeType={filterGroupType}
            filterRangeType={filterRangeType}
            filterLines={filterLines}
            filterGroupNames={filterGroupNames}
            filterBranches={filterBranches}
            handleSubmit={handleFilterApply}
            handleClick={handleClick}
          />
        </Wrapper>
      )}
      {openBottomSheet === BottomSheetType.CHART_VIEW_TYPE && (
        <Wrapper ref={clickBottomSheet} open={openBottomSheet}>
          <TitleWrapper>
            <div onClick={() => handleClick(BottomSheetType.FILTER)}>
              <Icon iconName="leftArrow" height={17} width={17} />
            </div>
            <Title>{t("BottomSheet.ChartViewType")}</Title>
            <IconButtonMobile handleClick={handleClose}>
              <Icon iconName="closeIcon" />
            </IconButtonMobile>
          </TitleWrapper>
          <SelectChartTypeMobile
            chartGroupeType={frontSideFilter.group}
            setChartGroupType={handleChangeViewType}
            handleClick={handleClick}
          />
        </Wrapper>
      )}
      {openBottomSheet === BottomSheetType.DATE_RANGE && (
        <Wrapper ref={clickBottomSheet} open={openBottomSheet}>
          <TitleWrapper>
            <div onClick={() => handleClick(BottomSheetType.FILTER)}>
              <Icon iconName="leftArrow" height={17} width={17} />
            </div>
            <Title>{t("BottomSheet.DataRange")}</Title>
            <IconButtonMobile handleClick={handleClose}>
              <Icon iconName="closeIcon" />
            </IconButtonMobile>
          </TitleWrapper>
          <DateRangeMobile
            filter={filter}
            isChanged={isChanged.date}
            dateRange={filterRangeType}
            handleDateRange={handleChangeDataRange}
            handleClick={handleClick}
            updateDate={handleUpdateDate}
          />
        </Wrapper>
      )}
      {openBottomSheet === BottomSheetType.DATE_TYPE && (
        <Wrapper ref={clickBottomSheet} open={openBottomSheet}>
          <TitleWrapper>
            <div onClick={() => handleClick(BottomSheetType.FILTER)}>
              <Icon iconName="leftArrow" height={17} width={17} />
            </div>
            <Title>{t("BottomSheet.DataRange")}</Title>
            <IconButtonMobile handleClick={handleClose}>
              <Icon iconName="closeIcon" />
            </IconButtonMobile>
          </TitleWrapper>
          <SelectDateTypeMobile
            periodType={filter.periodType}
            submitPeriodType={handleChangeDateType}
            handleClick={handleClick}
          />
        </Wrapper>
      )}

      {/* {openBottomSheet === BottomSheetType.GROUP_NAME && (
        <Wrapper ref={clickBottomSheet} open={openBottomSheet}>
          <TitleWrapper>
            <div onClick={() => handleClick(BottomSheetType.FILTER)}>
              <Icon iconName="leftArrow" height={17} width={17} />
            </div>
            <Title>{t("BottomSheet.GroupeName")}</Title>
            <IconButtonMobile handleClick={handleClose}>
              <Icon iconName="closeIcon" />
            </IconButtonMobile>
          </TitleWrapper>
          <SelectGroupName
            groupNameList={
              pageLocation === "Revenue"
                ? revenueGroupFilterOptions
                : pageLocation === "Expenses"
                ? expensesGroupFilterOptions
                : []
            }
            groupNames={filterGroupNames}
            submitBranch={handleGroupName}
            handleClick={handleClick}
          />
        </Wrapper>
      )} */}

      {openBottomSheet === BottomSheetType.BRANCH && (
        <Wrapper ref={clickBottomSheet} open={openBottomSheet}>
          <TitleWrapper>
            <div onClick={() => handleClick(BottomSheetType.FILTER)}>
              <Icon iconName="leftArrow" height={17} width={17} />
            </div>
            <Title>{t("BottomSheet.Branch")}</Title>
            <IconButtonMobile handleClick={handleClose}>
              <Icon iconName="closeIcon" />
            </IconButtonMobile>
          </TitleWrapper>
          <SelectBranchMobile
            branchList={companyBranches}
            branches={filterBranches}
            submitBranch={handleBranch}
            handleClick={handleClick}
          />
        </Wrapper>
      )}

      {openBottomSheet === BottomSheetType.BUSINESS_LINE && (
        <Wrapper ref={clickBottomSheet} open={openBottomSheet}>
          <TitleWrapper>
            <div onClick={() => handleClick(BottomSheetType.FILTER)}>
              <Icon iconName="leftArrow" height={17} width={17} />
            </div>
            <Title>{t("BottomSheet.BusinessLine")}</Title>
            <IconButtonMobile handleClick={handleClose}>
              <Icon iconName="closeIcon" />
            </IconButtonMobile>
          </TitleWrapper>
          <SelectBusinessLineMobile
            businessLineList={companyBusinessLine}
            businessLines={filterLines}
            submitBusinessLines={handleLine}
            handleClick={handleClick}
          />
        </Wrapper>
      )}
    </div>
  );
};

export default BottomSheet;
