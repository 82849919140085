import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div<{ isOpen: boolean }>(
  ({ theme, isOpen }) => css`
    ${theme.fonts.HelveticaNeue}
    z-index: 99999;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 12px 10px 16px;
    gap: 6px;
    border-radius: 8px;
    border: 1px solid
      ${isOpen ? theme.colors.greyShade200 : theme.colors.greyShade100};
    background: ${theme.colors.white};
    ${isOpen &&
    css`
      box-shadow: 0px 0px 0px 1px ${theme.colors.white},
        0px 0px 0px 3px ${theme.colors.primaryShade100};
    `}
    &:hover {
      ${!isOpen &&
      css`
        background: ${theme.colors.greyShade50};
      `}
    }
  `
);

export const Title = styled.p(
  ({ theme }) => css`
    margin: 0;
    color: ${theme.colors.textPrimary};
    font-size: 12px;
    line-height: normal;
  `
);

export const IconWrapper = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-in-out;

  &.rotate {
    transform: rotate(180deg);
  }
`;

export const OptionsWrapper = styled.div(
  ({ theme }) => css`
    position: absolute;
    border-radius: 8px;
    background: ${theme.colors.white};
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
    width: 148px;
    max-height: 144px;
    overflow: auto;
    top: 40px;
    left: 0;
  `
);

export const OptionItem = styled.div<{ isSelected: boolean }>(
  ({ theme, isSelected }) => css`
    padding-left: 16px;
    &:hover {
      background: ${!isSelected && theme.colors.greyShade50};
    }
    ${isSelected &&
    css`
      cursor: default;
    `}
  `
);

export const OptionContent = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${theme.colors.greyShade50};
    padding: 11px 16px 11px 0;
  `
);

export const OptionLabel = styled.p(
  ({ theme }) => css`
    ${theme.fonts.HelveticaLight}
    color: ${theme.colors.textPrimary};
    margin: 0;
    font-size: 12px;
    line-height: normal;
  `
);
