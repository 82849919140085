import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div(
  ({ theme }) => css`
    border-radius: 12px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.09);
    background-color: ${theme.colors.white};
    overflow: hidden;
  `
);

export const NoDataWrapper = styled.div`
  width: 100%;
  height: 410px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

export const Content = styled.div`
  width: 310px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
export const NoDataTitle = styled.h3(
  ({ theme }) => css`
    color: ${theme.colors.textPrimary};
    font-size: 13px;
    line-height: normal;
    padding: 0 50px;
  `
);

export const NoDataSubTitle = styled.h6(
  ({ theme }) => css`
    color: ${theme.colors.textSecondary};
    font-size: 11px;
    line-height: normal;
  `
);
