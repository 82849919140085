import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  IconWrapper,
  NumberOfSelectedValue,
  OptionsFooter,
  OptionsItem,
  OptionsItemWrapper,
  OptionsList,
  OptionsWrapper,
  Title,
  TitleWrapper,
  Wrapper,
} from "./FilterMultiSelector.style";

import Button from "../../../v2/button/Button";
import Icon, { IconNameOptions } from "../../icon/Icon";
import CheckBox from "../../checkBox/CheckBox";

interface Data {
  id: string;
  name: string;
}

interface DashboardFilterDropDownProps {
  title: string;
  selected: Array<string | number>;
  options: Array<Data>;
  iconName: IconNameOptions;
  isLoading?: boolean;
  handleSave: (values: Array<string | number>) => void;
}

const DashboardFilterDropDown = ({
  title,
  isLoading,
  selected,
  options,
  iconName,
  handleSave,
}: DashboardFilterDropDownProps) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState<Array<string | number>>([]);
  const [opened, setOpened] = useState(false);
  const dropDownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (options && !selected.length) {
      setChecked(options.map((item) => item.id));
    }
  }, [options, selected]);

  useEffect(() => {
    if (selected.length) {
      setChecked(selected.map((item) => item));
    }
  }, [selected]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropDownRef?.current &&
        !dropDownRef.current.contains(event.target as Node)
      ) {
        setChecked(() => {
          return selected.length ? selected : options?.map((item) => item.id);
        });
        setOpened(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selected, options]);

  const handleClick = (value: string) => {
    if (checked.includes(value)) {
      setChecked((prev) => prev.filter((item) => item !== value));
    } else {
      setChecked((prev) => [...prev, value]);
    }
  };

  const handleSelectAllClick = () => {
    setChecked(options.map((item) => item.id));
  };

  const checkType = (): "checked" | "partialChecked" | "unChecked" => {
    if (checked.length === options.length) {
      return "checked";
    }
    if (checked.length) {
      return "partialChecked";
    }
    return "unChecked";
  };

  return (
    <Wrapper
      ref={dropDownRef}
      hasSelectedValue={
        selected.length > 0 && selected.length !== options.length
      }
      onClick={() => setOpened((prev) => !prev)}
      isOpen={opened}
    >
      <IconWrapper>
        {selected.length > 0 && selected.length !== options.length ? (
          <Icon iconName="checkMark" height={16} width={16} fill="primary" />
        ) : (
          <Icon iconName={iconName} height={16} width={16} />
        )}
      </IconWrapper>
      <TitleWrapper
        hasSelectedValue={
          selected.length > 0 && selected.length !== options.length
        }
      >
        <Title
          hasSelectedValue={
            selected.length > 0 && selected.length !== options.length
          }
        >
          {title}
        </Title>
        {selected.length > 0 && selected.length !== options.length && (
          <NumberOfSelectedValue>{selected.length}</NumberOfSelectedValue>
        )}
      </TitleWrapper>
      <IconWrapper className={opened ? "rotate" : ""}>
        <Icon
          iconName="arrowDown"
          height={16}
          width={16}
          fill={
            selected.length > 0 && selected.length !== options.length
              ? "primary"
              : "default"
          }
        />
      </IconWrapper>
      {opened && (
        <OptionsWrapper>
          <OptionsList>
            <OptionsItemWrapper
              onClick={(e) => {
                e.stopPropagation();
                handleSelectAllClick();
              }}
            >
              <CheckBox
                checkType={checkType()}
                title=""
                handleChange={() => {}}
              />
              <OptionsItem lastItem={false}>
                {t("DashboardMultiSelector.SelectAllTitle")}
              </OptionsItem>
            </OptionsItemWrapper>
            {options.map((item, index) => (
              <OptionsItemWrapper
                key={item.id}
                onClick={(e) => {
                  e.stopPropagation();
                  handleClick(item.id);
                }}
              >
                <CheckBox
                  checkType={
                    checked.includes(item.id) ? "checked" : "unChecked"
                  }
                  title=""
                  handleChange={() => {}}
                />
                <OptionsItem lastItem={options.length - 1 === index}>
                  {item.name}
                </OptionsItem>
              </OptionsItemWrapper>
            ))}
          </OptionsList>
          <OptionsFooter>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setChecked([]);
              }}
              label={t("DashboardMultiSelector.ClearButtonTitle")}
              variant="text"
              size="small"
              disabled={!checked.length}
            />
            <Button
              disabled={!checked.length}
              onClick={() => {
                handleSave(checked);
              }}
              label={t("DashboardMultiSelector.SaveButtonTitle")}
              variant="filled"
              size="small"
            />
          </OptionsFooter>
        </OptionsWrapper>
      )}
    </Wrapper>
  );
};

export default DashboardFilterDropDown;
