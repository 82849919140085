import React from "react";
import {
  ChartHolder,
  Header,
  LeftSide,
  MainSection,
  MiddleSection,
  MiddleSectionItem,
  RightSide,
  RightSideDiv,
  RightSideSmallDiv,
  SubTitle,
  Title,
  Wrapper,
} from "./BigCardSkeleton.style";

const BigCardSkeleton = () => {
  const middleSectionArray = [161, 114, 201, 135, 76, 191];
  return (
    <Wrapper>
      <Header>
        <LeftSide>
          <Title />
          <SubTitle />
        </LeftSide>
        <RightSide>
          <RightSideSmallDiv />
          <RightSideDiv />
        </RightSide>
      </Header>
      <MiddleSection>
        {middleSectionArray.map((number) => (
          <MiddleSectionItem key={number} width={number} />
        ))}
      </MiddleSection>
      <MainSection>
        <ChartHolder />
      </MainSection>
    </Wrapper>
  );
};

export default BigCardSkeleton;
