import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const DateRangeWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    border-radius: 6px;
    border: 1px solid ${theme.colors.greyShade50};
    position: relative;
    cursor: pointer;
  `
);

export const DropDown = styled.div<{ isSelected: boolean }>(
  ({ theme, isSelected }) => css`
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 8px 12px;
    border-left: 1px solid ${theme.colors.greyShade50};
    background: ${isSelected
      ? theme.colors.primary200
      : theme.colors.greyShade100};
    position: relative;
    cursor: pointer;
  `
);

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;
export const ContentTitle = styled.div<{ isSelected: boolean }>(
  ({ theme, isSelected }) => css`
    color: ${isSelected ? theme.colors.primary600 : theme.colors.textPrimary};
    font-size: 13px;
    line-height: normal;
  `
);

export const IconWrapper = styled.div`
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-in-out;

  &.rotate {
    transform: rotate(180deg);
  }
`;

export const DropDownWrapper = styled.div(
  ({ theme }) => css`
    position: absolute;
    right: 0;
    top: 40px;
    height: 180px;
    width: 170px;
    border-radius: 8px;
    background: ${theme.colors.white};
    z-index: 1000;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
    overflow: auto;
  `
);

export const ListItem = styled.div(
  ({ theme }) => css`
    padding-left: 16px;

    &:hover {
      background: ${theme.colors.greyShade50};
    }
  `
);

export const ListItemContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px 12px 0;
    border-bottom: 1px solid ${theme.colors.greyShade50};
  `
);

export const ListItemTitle = styled.p(
  ({ theme }) => css`
    ${theme.fonts.HelveticaLight}
    font-size: 12px;
    line-height: normal;
  `
);

export const DateRangeCustomItem = styled.div<{ isSelected: boolean }>(
  ({ theme, isSelected }) => css`
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
    padding: 8px 12px;
    color: ${isSelected ? theme.colors.primary600 : theme.colors.textPrimary};
    font-size: 13px;
    line-height: normal;
    border-left: 1px solid ${theme.colors.greyShade50};
    background: ${isSelected
      ? theme.colors.primary200
      : theme.colors.greyShade100};
  `
);

export const DateRangeItem = styled.div<{ isSelected: boolean }>(
  ({ theme, isSelected }) => css`
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px 12px;
    color: ${isSelected ? theme.colors.primary600 : theme.colors.textPrimary};
    font-size: 13px;
    line-height: normal;
    border-left: 1px solid ${theme.colors.greyShade50};
    background: ${isSelected
      ? theme.colors.primary200
      : theme.colors.greyShade100};
  `
);

export const CalendarWrapper = styled.div<{
  custom: boolean;
}>(
  ({ custom }) => css`
    position: absolute;
    width: ${custom ? "210px" : "90px"};
    height: 100%;
    top: 0;
    opacity: 0;
    z-index: 10;

    & input,
    div,
    span {
      cursor: pointer;
    }
  `
);
