import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChartMode } from "../../../../enums/enum";
import Icon, { IconNameOptions } from "../../icon/Icon";
import {
  ViewSelectorWrapper,
  Selected,
  ViewWrapper,
  ViewText,
} from "./ChartTypeSwitcher.style";

interface ViewOption {
  mode: ChartMode;
  label: string;
  icon: IconNameOptions;
}

interface ChartTypeSwitcherProps {
  viewOptions: Array<ViewOption>;
  selectedViewMode: ChartMode;
  handleViewMode: (value: ChartMode) => void;
}

const ChartTypeSwitcher = ({
  viewOptions,
  selectedViewMode,
  handleViewMode,
}: ChartTypeSwitcherProps) => {
  const { t } = useTranslation();
  const elementRefs = useRef<HTMLDivElement[]>([]);
  const [elementWidths, setElementWidths] = useState<number[]>([]);

  useEffect(() => {
    if (elementRefs.current.length > 0) {
      const widths = elementRefs.current.map((el) => el?.offsetWidth || 0);
      setElementWidths(widths);
    }
  }, [viewOptions]);

  const selectedIndex = viewOptions.findIndex(
    (option) => option.mode === selectedViewMode
  );

  const calculateTranslateX = (index: number) => {
    return elementWidths
      .slice(0, index)
      .reduce((sum, width) => sum + width + 5, 0);
  };

  return (
    <ViewSelectorWrapper>
      {selectedIndex >= 0 && (
        <Selected
          width={elementWidths[selectedIndex] || 0}
          translateX={calculateTranslateX(selectedIndex)}
        />
      )}
      {viewOptions.map((option, index) => (
        <ViewWrapper
          key={option.label}
          ref={(el) => {
            if (el) elementRefs.current[index] = el;
          }}
          onClick={() => handleViewMode(option.mode)}
        >
          <Icon
            iconName={option.icon}
            height={16}
            width={16}
            fill={selectedViewMode === option.mode ? "black100" : "grey300"}
          />
          <ViewText isSelected={selectedViewMode === option.mode}>
            {t(option.label)}
          </ViewText>
        </ViewWrapper>
      ))}
    </ViewSelectorWrapper>
  );
};

export default ChartTypeSwitcher;
