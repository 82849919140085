import { useTranslation } from "react-i18next";
import {
  DateWrapper,
  FileDownloadTitle,
  FileDownLoadWrapper,
  ButtonWrapper,
  Wrapper,
} from "./Filters.style";

import Icon from "../../icon/Icon";
import { RangeType } from "../../../../enums/enum";
import DashboardFilterDropDown from "../filterMultiSelector/FilterMultiSelector";
import DashboardDateFilter from "../../../v2/dashboardDateFilter/DashboardDateFilter";
import LinkedButton from "../../../v2/linkedButton/LinkedButton";
// import FilterSkeleton from "../skeletons/filterSkeleton/FilterSkeleton";

interface Data {
  id: string;
  name: string;
}
interface FiltersProps {
  isShowClearAll: boolean;
  selectedDateRange: { type: RangeType; range: Array<string> };
  isLoading?: boolean;
  selectedGroups?: Array<string | number>;
  selectedBranches?: Array<string | number>;
  selectedBusinessLine?: Array<string | number>;
  groupOptions?: Array<Data>;
  lineOptions?: Array<Data>;
  branchOptions?: Array<Data>;
  handleDateRange: ({
    type,
    range,
  }: {
    type: RangeType;
    range: Array<string>;
  }) => void;
  handleBusinessLines?: (item: Array<string | number>) => void;
  handleBranches?: (item: Array<string | number>) => void;
  handleGroups?: (item: Array<string | number>) => void;
  handleClearAll: () => void;
  handleDownload?: () => void;
}

const Filters = ({
  isShowClearAll,
  selectedDateRange,
  isLoading = false,
  selectedGroups,
  selectedBranches,
  selectedBusinessLine,
  groupOptions,
  lineOptions,
  branchOptions,
  handleDateRange,
  handleBusinessLines,
  handleBranches,
  handleGroups,
  handleClearAll,
  handleDownload,
}: FiltersProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <DateWrapper>
        <DashboardDateFilter
          selectedDateRange={selectedDateRange}
          handleSelectDate={handleDateRange}
        />

        {selectedGroups &&
          Array.isArray(groupOptions) &&
          groupOptions.length > 0 &&
          handleGroups && (
            <DashboardFilterDropDown
              isLoading={isLoading}
              selected={selectedGroups}
              title={t("DashboardFilters.GroupsTitle")}
              iconName="people"
              options={groupOptions}
              handleSave={handleGroups}
            />
          )}

        {selectedBranches &&
          handleBranches &&
          Array.isArray(branchOptions) &&
          branchOptions.length > 0 && (
            <DashboardFilterDropDown
              selected={selectedBranches}
              title={t("DashboardFilters.BranchesTitle")}
              iconName="gitMerge"
              options={branchOptions}
              handleSave={handleBranches}
            />
          )}

        {handleBusinessLines &&
          selectedBusinessLine &&
          Array.isArray(lineOptions) &&
          lineOptions.length > 0 && (
            <DashboardFilterDropDown
              selected={selectedBusinessLine}
              title={t("DashboardFilters.BusinessLineTitle")}
              iconName="briefcase"
              options={lineOptions}
              handleSave={handleBusinessLines}
            />
          )}

        {isShowClearAll && (
          <ButtonWrapper>
            <LinkedButton
              label={t("DashboardFilters.ButtonText")}
              size="large"
              color="grey"
              onClick={handleClearAll}
            />
          </ButtonWrapper>
        )}
      </DateWrapper>
      {handleDownload && (
        <FileDownLoadWrapper onClick={handleDownload}>
          <FileDownloadTitle>
            {t("DashboardFilters.DownloadTitle")}
          </FileDownloadTitle>
          <Icon iconName="download" height={20} width={20} />
        </FileDownLoadWrapper>
      )}
    </Wrapper>
  );
};

export default Filters;
