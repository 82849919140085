import React from "react";
import { useTranslation } from "react-i18next";
import Icon from "../icon/Icon";
import {
  Content,
  NoDataSubTitle,
  NoDataTitle,
  NoDataWrapper,
  Wrapper,
} from "./EmptyDashboard.style";

const EmptyDashboard = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <NoDataWrapper>
        <Icon iconName="noResult" width={64} height={64} />
        <Content>
          <NoDataTitle>{t("EmptyDashboard.NoDataTitle")}</NoDataTitle>
          <NoDataSubTitle>{t("EmptyDashboard.NoDataSubTitle")}</NoDataSubTitle>
        </Content>
      </NoDataWrapper>
    </Wrapper>
  );
};

export default EmptyDashboard;
