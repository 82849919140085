import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  ChangeEvent,
} from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import {
  Wrapper,
  SelectField,
  SelectWrapper,
  HeaderWrapper,
  OptionItem,
  OptionsWrapper,
  Arrow,
  Label,
  OptionsBorder,
  OptionItemWrapper,
  ErrorWrapper,
  IconWrapper,
  ErrorText,
  SearchInput,
  CloseIconWrapper,
  TitleWrapper,
  Title,
  ActionWrapper,
  InputWrapper,
} from "./Select.style";
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from "formik";
import Icon from "../../shared/icon/Icon";

interface Data {
  value: string;
  label: string;
}

interface FormikFieldProps {
  field?: FieldInputProps<string>;
  meta?: FieldMetaProps<string>;
  helpers?: FieldHelperProps<string>;
}

interface SelectProps {
  label: string;
  options: Array<Data>;
  isDisabled?: boolean;
  selectedValue?: string;
  reversed?: boolean;
  errorText?: string;
  inputMode?: boolean;
  handleSelect?: (value: string) => void;
  onChange?: (value: string) => void;
}

type SelectFormikProps = SelectProps & FormikFieldProps;

const Select = ({
  label,
  options,
  isDisabled = false,
  selectedValue,
  reversed,
  errorText,
  inputMode,
  field,
  handleSelect,
  onChange,
}: SelectFormikProps) => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);
  const searchRef = useRef<HTMLInputElement>(null);
  const [searchEnabled, setSearchEnable] = useState(false);
  const [searchingText, setSearchingText] = useState<string>("");

  const [selectedLabel, setSelectedLabel] = useState<string>(
    options.find((option) => option.value === (field?.value || selectedValue))
      ?.label || ""
  );

  const filteredOptions = useMemo(
    () =>
      options.filter((option) =>
        option.label.toLowerCase().includes(searchingText.toLowerCase())
      ),
    [options, searchingText]
  );

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (inputMode && field) {
      field.onChange({ target: { name: field.name, value: e.target.value } });
      setSelectedLabel(e.target.value);
    } else {
      setSearchingText((prev) =>
        prev === selectedLabel || prev?.length === 0
          ? e.target.value.replace(selectedLabel, "")
          : e.target.value
      );
    }

    if (e.target.value?.length === 0) {
      setSelectedLabel("");
    }
  };

  const handleSelectField = (e: React.MouseEvent) => {
    if (isDisabled) {
      return;
    }

    e.stopPropagation();
    e.preventDefault();

    setOpened((prev) => !prev);

    if (!searchEnabled) {
      searchRef.current?.focus();
    }
    setSearchEnable((prev) => !prev);
    setSearchingText("");
  };

  const handleClear = (e: any) => {
    if (isDisabled) {
      return;
    }

    e.stopPropagation();
    setOpened(true);

    searchRef.current?.focus();
    setSearchEnable(true);

    handleSelection("");
  };

  const handleSelection = (value: string) => {
    if (field) {
      field.onChange({ target: { name: field.name, value } });
    } else if (handleSelect) {
      handleSelect(value);
    }

    if (onChange) {
      onChange(value);
    }
    setSearchingText("");
  };

  useEffect(() => {
    if (!inputMode) {
      setSelectedLabel(
        options.find(
          (option) => option.value === (field?.value || selectedValue)
        )?.label || ""
      );
    }
  }, [options, field?.value, inputMode, selectedValue]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef?.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setSearchingText("");
        setOpened(false);
        setSearchEnable(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <Wrapper>
      <SelectField ref={selectRef} onClick={handleSelectField}>
        <SelectWrapper opened={opened}>
          <HeaderWrapper opened={!!selectedLabel || opened}>
            <Label
              hasError={Boolean(errorText)}
              isDisabled={isDisabled}
              opened={opened}
            >
              {label}
            </Label>

            <InputWrapper onClick={handleSelectField}>
              <SearchInput
                ref={searchRef}
                value={searchingText || selectedLabel}
                onChange={handleSearchChange}
                isHidden={!searchEnabled || !opened}
              />

              {!opened && (
                <TitleWrapper>
                  <Title isDisabled={isDisabled}>{t(selectedLabel)}</Title>
                </TitleWrapper>
              )}
            </InputWrapper>
          </HeaderWrapper>

          <ActionWrapper>
            {selectedLabel && (
              <CloseIconWrapper onClick={handleClear}>
                <Icon iconName="closeIcon" height={20} width={20} />
              </CloseIconWrapper>
            )}

            <Arrow className={opened ? "rotate" : ""}>
              <Icon iconName="arrowDown" />
            </Arrow>
          </ActionWrapper>
        </SelectWrapper>

        <OptionsBorder reversed={Boolean(reversed)} opened={opened}>
          <OptionsWrapper opened={opened}>
            {filteredOptions.map((option) => (
              <OptionItemWrapper
                isMobile={isMobile}
                key={option.value}
                onClick={() => handleSelection(option.value)}
              >
                <OptionItem isSelected={selectedLabel === option.label}>
                  {option.label.includes(".") ? t(option.label) : option.label}
                  {selectedLabel === option.label && (
                    <Icon fill="primary" iconName="checkMark" />
                  )}
                </OptionItem>
              </OptionItemWrapper>
            ))}
          </OptionsWrapper>
        </OptionsBorder>
      </SelectField>
      {Boolean(errorText) && (
        <ErrorWrapper>
          <IconWrapper>
            <Icon iconName="closeIcon" height={9} width={9} fill="white" />
          </IconWrapper>
          <ErrorText>{errorText}</ErrorText>
        </ErrorWrapper>
      )}
    </Wrapper>
  );
};

export default Select;
