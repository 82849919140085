import { Pages, userPermissions } from "../store/user/user.types";

export const AuthURL = {
  Root: { path: "/" },
  Login: { path: "/login" },
  ForgotPassword: { path: "/forgot-password" },
  CheckEmail: { path: "/check-email" },
  ResetPassword: { path: "/reset-password" },
  ChooseAccount: { path: "/choose-account" },
};

export type PageType = {
  [key in Pages]: { path: string; permissionKey?: keyof userPermissions };
};

export const PageURL: PageType = {
  Root: { path: "/app" },
  Home: { path: "/app/home" },
  ImportData: { path: "/app/import-data", permissionKey: "ImportData" },
  Mapping: { path: "/app/Mapping", permissionKey: "Mapping" },
  UserManagement: {
    path: "/app/UserManagement",
    permissionKey: "Administration",
  },
  PersonalInfo: {
    path: "/app/updatePersonalDetail",
    permissionKey: "PersonalInfo",
  },
  UpdatePassword: {
    path: "/app/updatePassword",
    permissionKey: "PersonalInfo",
  },
  CreateCompany: { path: "/app/createCompany", permissionKey: "CreateCompany" },
  CompanyInfo: { path: "/app/CompanyInfo" },
  Integration: { path: "/app/Integration" },
  FAQ: { path: "/app/FAQ" },
  notFound: { path: "/app/404" },
  PermissionDenied: { path: "/app/permission-denied" },
  Revenue: { path: "/app/Revenue", permissionKey: "Revenue" },
  RevenueByGroupId: {
    path: "/app/Revenue/:groupId",
    permissionKey: "Revenue",
  },
  RevenueInDetail: {
    path: "/app/Revenue/:groupId/:accountId",
    permissionKey: "Revenue",
  },

  Expenses: { path: "/app/Expenses", permissionKey: "Expenses" },
  ExpensesByGroupId: {
    path: "/app/Expenses/:groupId",
    permissionKey: "Expenses",
  },
  ExpensesInDetail: {
    path: "/app/Expenses/:groupId/:accountId",
    permissionKey: "Expenses",
  },
  ProfitLoss: { path: "/app/ProfitLoss", permissionKey: "ProfitLoss" },
  Reportal: { path: "/app/Reportal", permissionKey: "Reportal" },
  CashFlow: { path: "/app/CashFlow", permissionKey: "CashFlow" },
  RevenueServices: {
    path: "/app/RevenueServices",
    permissionKey: "RevenueService",
  },
  Reports: { path: "/app/Reports", permissionKey: "Reports" },
  Market: { path: "/app/Market" },
};
