import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const ViewSelectorWrapper = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.greyShade50};
    position: relative;
    display: flex;
    align-items: center;
    padding: 4px;
    border-radius: 8px;
    gap: 4px;
  `
);

export const Selected = styled.div<{
  width: number;
  translateX: number;
}>(
  ({ theme, width, translateX }) => css`
    position: absolute;
    left: 4px;
    width: ${width}px;
    height: 30px;
    border-radius: 6px;
    background: ${theme.colors.white};
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.09);
    transition: transform 0.3s cubic-bezier(0.12, 0.62, 1, 1), width 0.3s;
    transform: translateX(${translateX}px);
  `
);

export const ViewWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 12px 6px 8px;
  gap: 6px;
  z-index: 1;
  cursor: pointer;
`;

export const ViewText = styled.p<{ isSelected: boolean }>(
  ({ theme, isSelected }) => css`
    color: ${isSelected
      ? theme.colors.textPrimary
      : theme.colors.textSecondary};
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  `
);
