import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  BarChartTitleContainer,
  GroupBarChartContainer,
  GroupDetailChartWrapper,
  Header,
  NavLink,
  SkeletonWrapper,
  Title,
  TitleWrapper,
  TreeChartContainer,
  Wrapper,
} from "./ExpensesInDetail.style";

import BigCardSkeleton from "../../../../components/shared/dashboard/skeletons/bigCardSkeleton/BigCardSkeleton";
import Filters from "../../../../components/shared/dashboard/filters/Filters";
import CardGroupsSkeleton from "../../../../components/shared/dashboard/skeletons/cardGroupsSkeleton/CardGroupsSkeleton";
import DashboardChartCard from "../../../../components/shared/card/Card";

import { useAppDispatch, useAppSelector } from "../../../../store";
import { getExpensesInDetail } from "../../../../store/dashboard/dashboardAction";
import {
  getCompanyBranch,
  getCompanyBusinessLine,
} from "../../../../store/company/companyAction";
import {
  CardTypes,
  ChartMode,
  ChartType,
  PeriodType,
  RangeType,
} from "../../../../enums/enum";

import TreeChart from "../../../../components/desktop/charts/treeChart/TreeChart";

import TableChart from "../../../../components/desktop/charts/tableChart/TableChart";
import {
  formatNumber,
  sumRevenueExpenses,
} from "../../../../helpers/dashboardHelper";
import { Link, useParams } from "react-router-dom";
import Icon from "../../../../components/shared/icon/Icon";
import BarChartLabels from "../../../../components/shared/dashboard/barChartLabels/BarChartLabels";
import GroupBarChart from "../../../../components/desktop/charts/groupBar/GroupBarChart";
import EmptyDashboard from "../../../../components/shared/emptyDashboard/EmptyDashboard";
import {
  initialRevenueExpensesFilterData,
  updateExpensesFilter,
} from "../../../../store/dashboard/dashboardSlice";

const ExpensesInDetail = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  let { groupId, accountId } = useParams();

  const [isShowClearAll, setIsShowClearAll] = useState(false);

  const [chartType] = useState<ChartType>(ChartType.GROUP);

  const [cardViewMode, setCardViewMode] = useState<ChartMode>(ChartMode.Tree);

  const expensesFilter = useAppSelector(
    (state) => state.dashboard.expensesFilter
  );

  const expenses = useAppSelector(
    (state) => state.dashboard.expensesDetailChartData
  );

  const loading = useAppSelector(
    (state) => state.dashboard.expensesChartLoading
  );

  const hasNoData = useMemo(() => {
    return expenses.data.every((item) => item.sumAmount === 0);
  }, [expenses.data]);

  const totalSum = useMemo(() => {
    return formatNumber(sumRevenueExpenses(expenses.data));
  }, [expenses.data]);

  useEffect(() => {
    const promises = [
      dispatch(getCompanyBusinessLine()),
      dispatch(getCompanyBranch()),
    ];
    Promise.all(promises).finally(() => {});
  }, [dispatch]);

  useEffect(() => {
    if (!accountId) return;

    dispatch(
      getExpensesInDetail({
        periodType: expensesFilter.period,
        fromDate: expensesFilter.dateRange.range[0],
        toDate: expensesFilter.dateRange.range[1],
        chartType,
        accountId,
      })
    );
  }, [dispatch, expensesFilter, accountId, chartType]);

  const updateFilter = ({
    periodType,
    dateRange,
    dateType,
  }: {
    periodType?: PeriodType;
    dateRange?: Array<string>;
    dateType?: RangeType;
  }) => {
    let data = { ...expensesFilter };

    if (periodType) {
      data.period = periodType;
    }

    if (dateRange) {
      data.dateRange = { ...data.dateRange, range: dateRange };
    }
    if (dateType) {
      data.dateRange = { ...data.dateRange, type: dateType };
    }

    dispatch(updateExpensesFilter(data));
  };

  const clearFilter = () => {
    dispatch(updateExpensesFilter(initialRevenueExpensesFilterData));
  };

  return (
    <Wrapper>
      <Header>
        <TitleWrapper>
          <Title>{t("ExpensesInDetail.Title")}</Title>
          <NavLink>
            <Link to={`/app/Expenses/${groupId}`}>
              <Icon iconName="leftArrow" height={12} width={7} />
              <span> {t("ExpensesInDetail.SubTitle")}</span>
            </Link>
          </NavLink>
        </TitleWrapper>
        <Filters
          isShowClearAll={isShowClearAll}
          selectedDateRange={expensesFilter.dateRange}
          handleDateRange={(dateData) => {
            setIsShowClearAll(true);
            updateFilter({
              dateRange: dateData.range,
              dateType: dateData.type,
            });
          }}
          handleClearAll={() => {
            setIsShowClearAll(false);
            clearFilter();
          }}
        />
      </Header>
      {loading.groupDetail === "pending" ? (
        <SkeletonWrapper>
          <BigCardSkeleton />
          <CardGroupsSkeleton />
        </SkeletonWrapper>
      ) : (
        <>
          {hasNoData ? (
            <EmptyDashboard />
          ) : (
            <>
              <DashboardChartCard
                selectedPeriodType={expensesFilter.period}
                selectedChartType={chartType}
                selectedViewType={ChartMode.Tree}
                type={CardTypes.LARGE}
                title={t("ExpensesInDetail.DashboardChartCardTitle")}
                value={totalSum}
                handlePeriodType={(periodType) => {
                  updateFilter({ periodType });
                }}
                handleViewMode={setCardViewMode}
              >
                {cardViewMode === ChartMode.Stacked && (
                  <>
                    <BarChartLabels data={expenses.data} />
                    <GroupBarChartContainer>
                      <GroupBarChart
                        data={expenses.data}
                        periodType={expensesFilter.period}
                        stackId={"a"}
                      />
                    </GroupBarChartContainer>
                  </>
                )}
                {cardViewMode === ChartMode.Tree && (
                  <TreeChartContainer>
                    <TreeChart data={expenses.data} />
                  </TreeChartContainer>
                )}
              </DashboardChartCard>
              <GroupDetailChartWrapper>
                <BarChartTitleContainer>
                  <Title>{t("ExpensesInDetail.BarChartTitle")}</Title>
                </BarChartTitleContainer>
                <TableChart
                  periodType={expensesFilter.period}
                  data={expenses.data}
                />
              </GroupDetailChartWrapper>
            </>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default ExpensesInDetail;
