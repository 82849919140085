import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const FileDownLoadWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;

export const FileDownloadTitle = styled.p(
  ({ theme }) => css`
    margin: 0;
    color: ${theme.colors.primary600};
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.084px;
  `
);

export const ButtonWrapper = styled.div`
  margin-left: 4px;
`;
