import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div<{
  hasSelectedValue: boolean;
  isOpen: boolean;
}>(
  ({ theme, isOpen, hasSelectedValue }) => css`
    cursor: pointer;
    position: relative;
    display: flex;
    gap: 6px;
    padding: 8px;
    border-radius: 6px;
    background-color: ${hasSelectedValue
      ? theme.colors.primaryShade100
      : theme.colors.greyShade100};
    ${theme.fonts.HelveticaNeue}
    ${isOpen &&
    css`
      box-shadow: 0px 0px 0px 1px #fff,
        0px 0px 0px 3px ${theme.colors.primaryShade100};
    `}
  `
);
export const IconWrapper = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-in-out;

  &.rotate {
    transform: rotate(180deg);
  }
`;

export const Title = styled.div<{ hasSelectedValue: boolean }>(
  ({ theme, hasSelectedValue }) => css`
    color: ${hasSelectedValue
      ? theme.colors.primary600
      : theme.colors.textPrimary};
    font-size: 13px;
    line-height: normal;
  `
);
export const OptionsWrapper = styled.div(
  ({ theme }) => css`
    position: absolute;
    top: 36px;
    left: 0;
    width: 260px;
    border-radius: 8px;
    background: ${theme.colors.white};
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
    z-index: 100000;
    overflow: hidden;
  `
);
export const OptionsList = styled.div`
  overflow: auto;
  padding: 8px 0 0;
  max-height: 180px;
`;

export const OptionsFooter = styled.div(
  ({ theme }) => css`
    padding: 8px 12px;
    gap: 8px;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid ${theme.colors.greyShade50};
  `
);

export const OptionsItemWrapper = styled.div`
  cursor: pointer;
  padding-left: 16px;
  display: flex;
  align-items: center;
`;

export const OptionsItem = styled.p<{ lastItem: boolean }>(
  ({ theme, lastItem }) => css`
    ${theme.fonts.HelveticaLight}
    ${!lastItem &&
    css`
      border-bottom: 1px solid ${theme.colors.greyShade50};
    `}
    margin: 0 !important;
    padding: 12px 24px 12px 0;
    width: 100%;
    font-size: 12px;
    line-height: normal;
  `
);

export const TitleWrapper = styled.div<{ hasSelectedValue: boolean }>(
  ({ hasSelectedValue }) => css`
    ${hasSelectedValue &&
    css`
      display: flex;
      align-items: center;
      gap: 4px;
    `}
  `
);
export const NumberOfSelectedValue = styled.div(
  ({ theme }) => css`
    border-radius: 4px;
    background: ${theme.colors.primaryShade100};
    color: ${theme.colors.primary600};
    padding: 2px 4px;
    font-size: 10px;
    line-height: 12px;
  `
);
