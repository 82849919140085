import React, { useEffect, useRef, useState } from "react";
import {
  IconWrapper,
  OptionsWrapper,
  OptionContent,
  OptionLabel,
  OptionItem,
  Title,
  Wrapper,
} from "./FilterSelector.style";

import { useTranslation } from "react-i18next";
import Icon from "../../icon/Icon";

interface FilterSelectorProps {
  selectedValue: string;
  options: Array<{ label: string; value: string }>;
  handlePeriod: (value: string) => void;
}
const FilterSelector = ({
  selectedValue,
  options,
  handlePeriod,
}: FilterSelectorProps) => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const dropDownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropDownRef?.current &&
        !dropDownRef.current.contains(event.target as Node)
      ) {
        setOpened(false);
      }
    };

    if (dropDownRef?.current) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropDownRef]);

  return (
    <Wrapper
      ref={dropDownRef}
      onClick={() => setOpened((prev) => !prev)}
      isOpen={opened}
    >
      <Title>
        {t(options.find((item) => item.value === selectedValue)?.label || "")}
      </Title>
      <IconWrapper className={opened ? "rotate" : ""}>
        <Icon iconName="arrowDown" height={20} width={20} fill="default" />
      </IconWrapper>
      {opened && (
        <OptionsWrapper>
          {options.map((item) => (
            <OptionItem
              isSelected={selectedValue === item.value}
              key={item.value}
              onClick={(e) => {
                e.stopPropagation();
                if (selectedValue === item.value) return;
                handlePeriod(item.value);
              }}
            >
              <OptionContent>
                <OptionLabel>{t(item.label)}</OptionLabel>
                {selectedValue === item.value && (
                  <Icon
                    width={14}
                    height={14}
                    fill="primary"
                    iconName="checkMark"
                  />
                )}
              </OptionContent>
            </OptionItem>
          ))}
        </OptionsWrapper>
      )}
    </Wrapper>
  );
};

export default FilterSelector;
