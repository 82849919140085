import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div(
  ({ theme }) => css`
    ${theme.fonts.HelveticaNeue}
    margin: 32px 0 72px;
    display: flex;
    flex-direction: column;
    gap: 40px;
  `
);

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const Title = styled.h6(
  ({ theme }) => css`
    color: ${theme.colors.textPrimary};
    font-size: 22px;
    line-height: normal;
  `
);

export const GroupBarChartContainer = styled.div`
  padding: 0px 24px 10px 4px;
`;
export const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;
export const BarChartItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
`;

export const NavLink = styled.div`
  width: fit-content;
  cursor: pointer;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const TreeChartContainer = styled.div`
  padding: 24px;
`;

export const GroupDetailChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
export const BarChartTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
