import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../store";

import {
  CustomTable,
  ExportExcelWrapper,
  LoaderWrapper,
  TableRow,
  TableRowItem,
  TableRowItemId,
  TableRowItemName,
} from "./Period.style";
import Loader from "../../../../../components/shared/loader/Loader";
import TableHeader from "../../../../../components/shared/tableHeader/TableHeader";
import ReportFilter from "../../../../../components/desktop/reportFilter/ReportFilter";
import { formatNumber } from "../../../../../helpers/dashboardHelper";
import { PeriodType } from "../../../../../enums/enum";
import moment from "moment";

import Button from "../../../../../components/shared/button/Button";
import {
  getClientPeriodFileUrl,
  getRevenuePeriod,
} from "../../../../../store/report/reportAction";
import { downloadFile } from "../../../../../helpers/helper";
import { useTranslation } from "react-i18next";

const Period = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [period, setPeriod] = useState(PeriodType.Month);
  const [fileDownloadLoading, setFileDownloadLoading] = useState(false);

  const [dateRange, setDateRange] = useState({
    fromDate: moment().subtract(1, "year").format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
  });

  const selectedCompany = useAppSelector((state) => state.user.selectedCompany);

  const revenuePeriod = useAppSelector((state) => state.report.revenue.period);

  const loader = useAppSelector((state) => state.dashboard.loading);

  useEffect(() => {
    dispatch(getRevenuePeriod({ ...dateRange, PeriodType: period }));
  }, [dispatch, selectedCompany, period, dateRange]);

  const handleFileDownload = () => {
    setFileDownloadLoading(true);
    dispatch(getClientPeriodFileUrl({ ...dateRange, PeriodType: period }))
      .then(({ payload }) => {
        downloadFile(payload as string);
        setTimeout(() => {
          setFileDownloadLoading(false);
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          setFileDownloadLoading(false);
        }, 500);
      });
  };

  const submit = ({
    fromDate,
    toDate,
    periodType,
  }: {
    fromDate?: string;
    toDate?: string;
    periodType?: PeriodType;
  }) => {
    if (periodType) {
      setPeriod(periodType);
    }
    if (fromDate && toDate) {
      setDateRange({
        fromDate: moment(fromDate).format("YYYY-MM-DD"),
        toDate: moment(toDate).format("YYYY-MM-DD"),
      });
    }
  };

  return (
    <>
      <ReportFilter dates={dateRange} handleSubmit={submit} />
      <ExportExcelWrapper>
        <Button
          isLoading={fileDownloadLoading}
          onClick={handleFileDownload}
          title={t("Export.FileExport")}
        ></Button>
      </ExportExcelWrapper>

      {loader === "pending" ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        <>
          <CustomTable>
            <TableHeader headerNames={revenuePeriod.labels} />
            <tbody>
              {revenuePeriod.data.map((account, index) => (
                <TableRow key={index}>
                  <TableRowItemId>{account.debitAccountId}</TableRowItemId>
                  <TableRowItemName>
                    {account.debitAccountName}
                  </TableRowItemName>
                  {account.periodTotals.map((item, index) => (
                    <TableRowItem key={index}>
                      {formatNumber(item.total)}
                    </TableRowItem>
                  ))}
                  <TableRowItem>{formatNumber(+account.total)}</TableRowItem>
                </TableRow>
              ))}
            </tbody>
          </CustomTable>
        </>
      )}
    </>
  );
};

export default Period;
