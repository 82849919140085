import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BarChartTitleContainer,
  GroupBarChartContainer,
  GroupDetailChartWrapper,
  Header,
  LastUpdated,
  SkeletonWrapper,
  Title,
  TitleWrapper,
  TreeChartContainer,
  UpdateDateSkeleton,
  Wrapper,
} from "./Revenue.style";
import BigCardSkeleton from "../../../../components/shared/dashboard/skeletons/bigCardSkeleton/BigCardSkeleton";
import {
  dashboardDateFormat,
  downloadFile,
  generateGridChartModes,
} from "../../../../helpers/helper";
import Filters from "../../../../components/shared/dashboard/filters/Filters";
import CardGroupsSkeleton from "../../../../components/shared/dashboard/skeletons/cardGroupsSkeleton/CardGroupsSkeleton";
import DashboardChartCard from "../../../../components/shared/card/Card";

import { useAppDispatch, useAppSelector } from "../../../../store";
import {
  getRevenueGroups,
  getRevenueGroupsExcel,
} from "../../../../store/dashboard/dashboardAction";
import {
  getCompanyBranch,
  getCompanyBusinessLine,
} from "../../../../store/company/companyAction";
import {
  CardTypes,
  ChartMode,
  ChartType,
  PeriodType,
  RangeType,
} from "../../../../enums/enum";
import { FinancialGroupsRequest } from "../../../../store/dashboard/dashboard.types";
import GroupBarChart from "../../../../components/desktop/charts/groupBar/GroupBarChart";
import TreeChart from "../../../../components/desktop/charts/treeChart/TreeChart";
import BarChartLabels from "../../../../components/shared/dashboard/barChartLabels/BarChartLabels";

import TableChart from "../../../../components/desktop/charts/tableChart/TableChart";
import ChartTypeSwitcher from "../../../../components/shared/dashboard/chartTypeSwitcher/ChartTypeSwitcher";
import GridChart from "../../../../components/desktop/charts/gridChart/GridChart";
import {
  formatNumber,
  sumRevenueExpenses,
} from "../../../../helpers/dashboardHelper";

import { useNavigate } from "react-router-dom";
import { ActionType } from "../../../../store/type";
import { isMobile } from "react-device-detect";
import EmptyDashboard from "../../../../components/shared/emptyDashboard/EmptyDashboard";
import {
  initialRevenueExpensesFilterData,
  updateRevenueFilter,
} from "../../../../store/dashboard/dashboardSlice";

const Revenue = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isShowClearAll, setIsShowClearAll] = useState(false);

  const [cardViewMode, setCardViewMode] = useState<ChartMode>(
    ChartMode.Stacked
  );

  const [selectedChartType, setSelectedChartType] = useState<ChartType>(
    ChartType.GROUP
  );

  const [viewMode, setViewMode] = useState<ChartMode>(ChartMode.Grid);

  const companyBusinessLine = useAppSelector(
    (state) => state.company.companyBusinessLine
  );

  const companyBranches = useAppSelector(
    (state) => state.company.companyBranch
  );

  const revenueFilter = useAppSelector(
    (state) => state.dashboard.revenueFilter
  );

  const revenue = useAppSelector(
    (state) => state.dashboard.revenueGroupChartData
  );

  const selectedCompany = useAppSelector((state) => state.user.selectedCompany);

  const revenueGroupsOption = useAppSelector(
    (state) => state.dashboard.revenueGroupsOption
  );
  const loading = useAppSelector(
    (state) => state.dashboard.revenueChartLoading
  );

  const hasNoData = useMemo(() => {
    return revenue.data.every((item) => item.sumAmount === 0);
  }, [revenue.data]);

  const totalSum = useMemo(() => {
    return formatNumber(sumRevenueExpenses(revenue.data));
  }, [revenue.data]);

  useEffect(() => {
    const promises = [
      dispatch(getCompanyBusinessLine()),
      dispatch(getCompanyBranch()),
    ];
    Promise.all(promises).finally(() => {});
  }, [dispatch, selectedCompany]);

  useEffect(() => {
    dispatch(
      getRevenueGroups({
        periodType: revenueFilter.period,
        fromDate: revenueFilter.dateRange.range[0],
        toDate: revenueFilter.dateRange.range[1],
        chartType: ChartType.GROUP,
      })
    );
  }, [dispatch, revenueFilter, selectedCompany]);

  const handleClearAll = () => {
    dispatch(updateRevenueFilter(initialRevenueExpensesFilterData));
  };

  const handleFilter = ({
    businessLines,
    branches,
    groups,
    periodType,
    chartType,
    dateRange,
    dateType,
  }: {
    businessLines?: Array<string | number>;
    branches?: Array<string | number>;
    groups?: Array<string | number>;
    periodType?: PeriodType;
    chartType?: ChartType;
    dateRange?: Array<string>;
    dateType?: RangeType;
  }) => {
    let data: FinancialGroupsRequest = {
      periodType: periodType ? periodType : revenueFilter.period,
      fromDate: dateRange?.length
        ? dateRange[0]
        : revenueFilter.dateRange.range[0],
      toDate: dateRange?.length
        ? dateRange[1]
        : revenueFilter.dateRange.range[1],
      chartType: chartType ? chartType : selectedChartType,
    };
    if (businessLines?.length || revenueFilter.businessLines.length) {
      data = {
        ...data,
        companyBusinessLineIds: businessLines?.length
          ? businessLines
          : revenueFilter.businessLines,
      };
    }
    if (branches?.length || revenueFilter.branches.length) {
      data = {
        ...data,
        companyBranchIds: branches?.length ? branches : revenueFilter.branches,
      };
    }
    if (groups?.length || revenueFilter.groups.length) {
      data = {
        ...data,
        accountMappingGroupIds: groups?.length ? groups : revenueFilter.groups,
      };
    }

    dispatch(
      updateRevenueFilter({
        dateRange:
          {
            type:
              dateType !== undefined ? dateType : revenueFilter.dateRange.type,
            range: [data.fromDate, data.toDate],
          } || revenueFilter.dateRange,
        period: data.periodType || revenueFilter.period,
        groups: data.accountMappingGroupIds || revenueFilter.groups,
        branches: data.companyBranchIds || revenueFilter.branches,
        businessLines:
          data.companyBusinessLineIds || revenueFilter.businessLines,
      })
    );
  };

  const handleNavigationGroupDetail = (groupId: number | string) => {
    navigate(groupId.toString());
  };

  const handleDownload = () => {
    const data: FinancialGroupsRequest = {
      periodType: revenueFilter.period,
      fromDate: revenueFilter.dateRange.range[0],
      toDate: revenueFilter.dateRange.range[1],
      chartType: selectedChartType,
      companyBranchIds: revenueFilter.branches,
      companyBusinessLineIds: revenueFilter.businessLines,
      accountMappingGroupIds: revenueFilter.groups,
    };

    dispatch(getRevenueGroupsExcel(data))
      .then((action: ActionType) => {
        if (action.error) {
          console.error("Download failed:", action.error);
        } else if (action?.payload) {
          downloadFile(action?.payload);
        }
      })
      .catch((error) => {
        console.error("Unexpected error during download:", error);
      });
  };

  if (isMobile) return <div>under construction</div>;

  return (
    <Wrapper>
      <Header>
        <TitleWrapper>
          <Title>{t("Revenue.Title")}</Title>
          {revenue.lastUpdateTime ? (
            <LastUpdated>
              {t("Revenue.DateUpdatedTitle")}:{" "}
              {dashboardDateFormat(revenue.lastUpdateTime)}
            </LastUpdated>
          ) : (
            <UpdateDateSkeleton />
          )}
        </TitleWrapper>

        <Filters
          isShowClearAll={isShowClearAll}
          groupOptions={revenueGroupsOption}
          selectedDateRange={revenueFilter.dateRange}
          selectedBranches={revenueFilter.branches}
          selectedGroups={revenueFilter.groups}
          selectedBusinessLine={revenueFilter.businessLines}
          isLoading={false}
          branchOptions={companyBranches.map((item) => ({
            id: item.id.toString(),
            name: item.name,
          }))}
          lineOptions={companyBusinessLine.map((item) => ({
            id: item.id.toString(),
            name: item.name,
          }))}
          handleDateRange={(dateData) => {
            setIsShowClearAll(true);

            handleFilter({
              dateRange: dateData.range,
              dateType: dateData.type,
            });
          }}
          handleBusinessLines={(businessLines) => {
            setIsShowClearAll(true);
            handleFilter({ businessLines });
          }}
          handleBranches={(branches) => {
            setIsShowClearAll(true);
            handleFilter({ branches });
          }}
          handleGroups={(groups) => {
            setIsShowClearAll(true);
            handleFilter({ groups });
          }}
          handleClearAll={() => {
            setIsShowClearAll(false);
            handleClearAll();
          }}
          handleDownload={handleDownload}
        />
      </Header>

      {loading.groups === "pending" ? (
        <SkeletonWrapper>
          <BigCardSkeleton />
          <CardGroupsSkeleton />
        </SkeletonWrapper>
      ) : (
        <>
          {hasNoData ? (
            <EmptyDashboard />
          ) : (
            <>
              <DashboardChartCard
                selectedPeriodType={revenueFilter.period}
                selectedChartType={selectedChartType}
                selectedViewType={cardViewMode}
                type={CardTypes.LARGE}
                title={t("Revenue.DashboardCardTitle")}
                value={totalSum}
                hasBranch={Boolean(companyBranches.length)}
                hasLine={Boolean(companyBusinessLine.length)}
                handlePeriodType={(periodType) => {
                  handleFilter({ periodType });
                }}
                handleChartType={(chartType) => {
                  setSelectedChartType(chartType);
                  handleFilter({ chartType });
                }}
                handleViewMode={setCardViewMode}
              >
                {cardViewMode === ChartMode.Stacked && (
                  <>
                    <BarChartLabels data={revenue.data} />
                    <GroupBarChartContainer>
                      <GroupBarChart
                        data={revenue.data}
                        periodType={revenueFilter.period}
                        stackId={"a"}
                      />
                    </GroupBarChartContainer>
                  </>
                )}
                {cardViewMode === ChartMode.Tree && (
                  <TreeChartContainer>
                    <TreeChart data={revenue.data} />
                  </TreeChartContainer>
                )}
              </DashboardChartCard>
              <GroupDetailChartWrapper>
                <BarChartTitleContainer>
                  <Title>{t("Revenue.GroupChartTitle")}</Title>
                  <ChartTypeSwitcher
                    viewOptions={generateGridChartModes()}
                    selectedViewMode={viewMode}
                    handleViewMode={setViewMode}
                  />
                </BarChartTitleContainer>
                {viewMode === ChartMode.Grid && (
                  <GridChart
                    handleViewDetail={
                      selectedChartType === ChartType.GROUP
                        ? handleNavigationGroupDetail
                        : undefined
                    }
                    periodType={revenueFilter.period}
                    data={revenue.data}
                  />
                )}
                {viewMode === ChartMode.Table && (
                  <TableChart
                    handleClick={
                      selectedChartType === ChartType.GROUP
                        ? handleNavigationGroupDetail
                        : undefined
                    }
                    periodType={revenueFilter.period}
                    data={revenue.data}
                  />
                )}
              </GroupDetailChartWrapper>
            </>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default Revenue;
