import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { chartColors } from "../../../../../constants/color";
import { generateProfitLossLabels } from "../../../../../helpers/dashboardHelper";
import { ProfitLossResponseRecord } from "../../../../../store/dashboard/dashboard.types";

import {
  ColorBox,
  ListItem,
  ListItemWrapper,
  Wrapper,
} from "./ProfitLossBarChartLabels.style";

interface IProps {
  data: ProfitLossResponseRecord[];
}
const ProfitLossBarChartLabels = ({ data }: IProps) => {
  const { t } = useTranslation();

  const listLabels = useMemo(() => {
    return generateProfitLossLabels(data);
  }, [data]);

  return (
    <Wrapper>
      {listLabels.map((itemName, index) => (
        <ListItemWrapper key={index}>
          <ColorBox color={chartColors[index]} />
          <ListItem>{t(itemName)}</ListItem>
        </ListItemWrapper>
      ))}
    </Wrapper>
  );
};

export default ProfitLossBarChartLabels;
