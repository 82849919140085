import React, { useState } from "react";
import Icon, { IconNameOptions } from "../../../shared/icon/Icon";
import { HoverItem, Title, Wrapper } from "./SideBarNavigationItem.style";
import { isMobile } from "react-device-detect";
import { useMatch, useNavigate } from "react-router-dom";

interface SideBarNavigationItemProps {
  pageName: string;
  iconName: IconNameOptions;
  path: string;
  active: boolean;
  handleClose?: () => void;
}
const SideBarNavigationItem = ({
  pageName,
  iconName,
  path,
  active,
  handleClose,
}: SideBarNavigationItemProps) => {
  const match = useMatch(path);
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Wrapper
      isMobile={isMobile}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        navigate(path);
        if (handleClose) handleClose();
      }}
      isSelected={Boolean(match)}
    >
      <Icon fill={Boolean(match) ? "primary" : "default"} iconName={iconName} />
      <Title isMobile={isMobile} isSelected={Boolean(match)} active={active}>
        {pageName}
      </Title>
      {isHovered && !active && <HoverItem>{pageName}</HoverItem>}
    </Wrapper>
  );
};

export default SideBarNavigationItem;
